import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewAssessment = lazy(() => import('../views/AssessmentPage/ViewAssessment'));
const CreateAssessment = lazy(() => import('../views/AssessmentPage/CreateAssessment'));
const EditAssessment = lazy(() => import('../views/AssessmentPage/EditAssessment'));
const DeleteAssessment = lazy(() => import('../views/AssessmentPage/DeleteAssessment'));
const AssessmentPage = lazy(() => import('../views/AssessmentPage/Assessments'));

function AssessmentRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateAssessment/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><AssessmentPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewAssessment/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteAssessment/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditAssessment/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default AssessmentRoutes;
