import React, { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AssessmentRoutes from './AssessmentRoutes'
import ClassRoutes from './ClassRoutes'
import InvoiceRoutes from './InvoiceRoutes'
import NewsRoutes from './NewsRoutes'
import NoteRoutes from './NoteRoutes'
import ParentRoutes from './ParentRoutes'
import PeriodRoutes from './PeriodRoutes'
import QuestionRoutes from './QuestionRoutes'
import RoutesUnderTopBar from './RoutesUnderTopBar'
import SchoolRoutes from './SchoolRoutes'
import StudentRoutes from './StudentRoutes'
import SubjectRoutes from './SubjectRoutes'
import TeacherRoutes from './TeacherRoutes'
import SuspenseComponent from '../components/SuspenseComponent'
import ThemeContextProvider from '../contexts/theme/ThemeContextProvider'
import PaymentsPage from '../views/InvoicePage/PaymentsPage'

const Login = lazy(() => import('../views/LoginPage/Login'))
const FirstTimeLogin = lazy(() => import('../views/LoginPage/FirstTimeLogin'))
const ChangeSchool = lazy(() => import('../views/PreDashboard/ChangeSchool'))
const UserSuspendedMessage = lazy(() =>
  import('../views/PreDashboard/UserSuspendedMessage'),
)
const ZoomRedirect = lazy(() => import('../views/ZoomPage/ZoomRedirect'))
const DashboardPage = lazy(() => import('../views/Dashboard/DashboardPage'))
const AnsweredQuestions = lazy(() =>
  import('../views/QuestionPage/QuestionsAndAnswers/AnsweredQuestions'),
)
const QuestionsAndAnswers = lazy(() =>
  import('../views/QuestionPage/QuestionsAndAnswers'),
)
const FullScoreSheet = lazy(() =>
  import('../views/AssessmentPage/FullScoreSheet'),
)
const FullScoresheetSubmission = lazy(() =>
  import(
    '../views/AssessmentPage/components/FullscoreSheet/FullScoresheetSubmission'
  ),
)
const Navigation = lazy(() => import('../components/Navigation'))

function AllRoutes() {
  return (
    <ThemeContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <SuspenseComponent>
                <Login />
              </SuspenseComponent>
            }
          />
          <Route
            path="/completeLogin"
            element={
              <SuspenseComponent>
                <FirstTimeLogin />
              </SuspenseComponent>
            }
          />
          <Route
            path="/changeSchool"
            element={
              <SuspenseComponent>
                <ChangeSchool />
              </SuspenseComponent>
            }
          />
          <Route
            path="/userSuspended"
            element={
              <SuspenseComponent>
                <UserSuspendedMessage />
              </SuspenseComponent>
            }
          />
          <Route
            path="/zoom"
            element={
              <SuspenseComponent>
                <ZoomRedirect />
              </SuspenseComponent>
            }
          />
          <Route
            path="/"
            element={
              <SuspenseComponent>
                <Navigation />
              </SuspenseComponent>
            }
          >
            <Route
              path="/qa/:id"
              element={
                <SuspenseComponent>
                  <QuestionsAndAnswers />
                </SuspenseComponent>
              }
            />
            <Route
              path="/scoresheet/:subjectId/:assessmentId"
              element={
                <SuspenseComponent>
                  <FullScoreSheet />
                </SuspenseComponent>
              }
            >
              <Route
                path="/scoresheet/:subjectId/:assessmentId/:studentId"
                element={
                  <SuspenseComponent>
                    <FullScoresheetSubmission />
                  </SuspenseComponent>
                }
              />
            </Route>
            {/* <Route path="/" element={ <SuspenseComponent><TopBar header={ 'To Do' }/></SuspenseComponent> }> */}
            <Route path="/*" element={<RoutesUnderTopBar />} />
            <Route
              path="/dashboard"
              element={
                <SuspenseComponent>
                  <DashboardPage />
                </SuspenseComponent>
              }
            />
            <Route
              path="/payments"
              element={
                <SuspenseComponent>
                  <PaymentsPage />
                </SuspenseComponent>
              }
            />
            <Route
              path="/revisions/:assessmentId"
              element={
                <SuspenseComponent>
                  <AnsweredQuestions />
                </SuspenseComponent>
              }
            />
            <Route path="/assessments/*" element={<AssessmentRoutes />} />
            <Route path="/notes/*" element={<NoteRoutes />} />
            <Route path="/news/*" element={<NewsRoutes />} />
            <Route path="/subjects/*" element={<SubjectRoutes />} />
            <Route path="/classes/*" element={<ClassRoutes />} />
            <Route path="/students/*" element={<StudentRoutes />} />
            <Route path="/parents/*" element={<ParentRoutes />} />
            <Route path="/teachers/*" element={<TeacherRoutes />} />
            <Route path="/schools/*" element={<SchoolRoutes />} />
            <Route path="/questions/*" element={<QuestionRoutes />} />
            <Route path="/periods/*" element={<PeriodRoutes />} />
            <Route path="/invoices/*" element={<InvoiceRoutes />} />
          </Route>
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </ThemeContextProvider>
  )
}

export default AllRoutes
