import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { Fragment, useContext, useState } from 'react'
import JitsuTable from '../../../components-elements/JitsuTable'
import JitsuTableCell from '../../../components-elements/JitsuTableCell'
import JitsuTableRow from '../../../components-elements/JitsuTableRow'
import ErrorView from '../../../components/ErrorView'
import Spinner from '../../../components/Spinner'
import { NAIRA_SIGN } from '../../../constants/common'
import { NotificationContext } from '../../../contexts/notifications/NotificationContextProvider'
import { numberWithCommas } from '../../../functions'
import { changeDateToReadableTime } from '../../../functions/date'
import useAppTheme from '../../../hooks/useAppTheme'
import { invoiceQueries } from '../../../queries'
import PaymentsFilter from './PaymentsFilter'
import EmptyState from '../../../components/EmptyState'
import { PAYMENT_METHODS_DISPLAY_TEXT } from '../../../constants/payment'
import RecordPayment from './RecordPayment'
import { useIsLargeScreen } from '../../../hooks/useIsLargeScreen'

export const columnKeys = {
  teacher: 'teacher',
  student: 'student',
  invoice: 'invoice',
  amount: 'amount',
  parent: 'parent',
  identificationCode: 'identificationCode',
  method: 'method',
  createdAt: 'createdAt',
}

const possibleColumns = {
  [columnKeys.teacher]: {
    columnName: 'Recorded by',
    getContent: ({ teacherFirstName, teacherLastName }) =>
      `${teacherFirstName} ${teacherLastName}`,
    getExtraContent: ({ teacherRegNumber }) => `${teacherRegNumber}`,
    link: ({ teacherId }) =>
      teacherId ? { to: `/teachers/${teacherId}` } : undefined,
  },
  [columnKeys.student]: {
    columnName: 'Student',
    getContent: ({ studentFirstName, studentLastName }) =>
      `${studentFirstName} ${studentLastName}`,
    getExtraContent: ({ studentRegNumber }) => studentRegNumber,
    link: ({ studentId }) => ({ to: `/students/${studentId}` }),
  },
  [columnKeys.parent]: {
    columnName: 'Paid by',
    getContent: ({ parentFirstName, parentLastName }) =>
      parentFirstName ? `${parentFirstName} ${parentLastName}` : '',
    getExtraContent: ({ parentRegNumber }) => parentRegNumber || '',
    link: ({ parentId }) =>
      parentId ? { to: `/parents/${parentId}` } : undefined,
  },
  [columnKeys.invoice]: {
    columnName: 'Invoice',
    getContent: ({ invoiceName }) => `${invoiceName}`,
    getExtraContent: ({ invoiceCode }) => `${invoiceCode}`,
    link: ({ invoiceId }) => ({ to: `/invoices/${invoiceId}` }),
  },
  [columnKeys.amount]: {
    columnName: 'Amount',
    getContent: ({ amount }) => `${amount || 0}`,
    isAmount: true,
  },
  [columnKeys.identificationCode]: {
    columnName: 'Receipt ID',
    getContent: ({ identificationCode }) => `${identificationCode}`,
  },
  [columnKeys.method]: {
    columnName: 'Method',
    getContent: ({ method }) =>
      method ? `${PAYMENT_METHODS_DISPLAY_TEXT[method] || method}` : 'Unknown',
  },
  [columnKeys.createdAt]: {
    columnName: 'Date',
    getContent: ({ createdAt }) => changeDateToReadableTime(createdAt),
  },
}

const getColumnContent = (column, record) => {
  if (column.isAmount) {
    return `${NAIRA_SIGN}${numberWithCommas(column.getContent(record))}`
  }
  return column.getContent(record)
}

function Payments({ first, hideFilter, columns, hideHeader }) {
  const selectedColumns = columns.map((columnKey) => possibleColumns[columnKey])
  const headers = selectedColumns.map(({ columnName }) => columnName)
  const isLargeScreen = useIsLargeScreen()
  const [filter, setFilter] = useState({})
  const [{ notifyError }] = useContext(NotificationContext)
  const pagination = {
    first,
    ...filter,
  }
  const { data, loading, error, fetchMore } = useQuery(
    invoiceQueries.paginatedPayments,
    {
      variables: {
        pagination,
      },
    },
  )
  const { textPrimary700 } = useAppTheme()
  const clickNext = async () => {
    try {
      await fetchMore({
        variables: { after: data.getPaginatedPayments.pageInfo.endCursor },
      })
    } catch (e) {
      notifyError()
    }
  }
  const onChangeFilter = (value) => {
    setFilter(value)
  }

  const { pageInfo, edges } = data?.getPaginatedPayments || {}

  return (
    <div className={isLargeScreen ? '' : 'p-4'}>
      <div className="flex justify-between">
        {!hideHeader && (
          <div className="text-sm py-2 text-gray-600">Payment records</div>
        )}
        {hideFilter && (
          <Link to="/payments" className="text-xs pt-2 text-gray-600">
            View all
          </Link>
        )}
      </div>
      {!hideFilter && (
        <div className="flex">
          <PaymentsFilter onChange={onChangeFilter} />
        </div>
      )}
      <RecordPayment isButton />
      {error && <ErrorView error={error} />}
      {loading && <Spinner />}
      {!error && !loading && (
        <div>
          {edges.length > 0 ? (
            <JitsuTable headers={['No', ...headers]}>
              {edges.map((record, index) => (
                <JitsuTableRow key={record.node.id}>
                  <>
                    <JitsuTableCell>{index + 1}.</JitsuTableCell>
                    {selectedColumns.map((column) => {
                      const Tag = column.link ? Link : Fragment
                      const props = column.link ? column.link(record.node) : {}
                      return (
                        <JitsuTableCell key={column.columnName}>
                          <Tag {...props}>
                            {getColumnContent(column, record.node)}
                            {column.getExtraContent && (
                              <span className="ml-2 text-gray-400">
                                {column.getExtraContent(record.node)}
                              </span>
                            )}
                          </Tag>
                        </JitsuTableCell>
                      )
                    })}
                  </>
                </JitsuTableRow>
              ))}
            </JitsuTable>
          ) : (
            <EmptyState />
          )}
          {pageInfo?.hasNextPage && !hideFilter && (
            <div
              onClick={clickNext}
              role="button"
              className={`text-right ${textPrimary700} cursor-pointer text-sm underline mt-4 font-light pb-8`}
            >
              Fetch more
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Payments.defaultProps = {
  first: 10,
  columns: Object.values(columnKeys),
}

Payments.propTypes = {
  first: PropTypes.number.isRequired,
  hideFilter: PropTypes.bool,
  hideHeader: PropTypes.bool,
  columns: PropTypes.array,
}

export default Payments
