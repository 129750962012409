import React from 'react'
import Payments from './components/Payments'

function PaymentsPage() {
  return (
    <div className="px-8 pb-8 mt-4 h-full overflow-auto">
      <Payments hideHeader />
    </div>
  )
}

export default PaymentsPage
