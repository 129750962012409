import gql from 'graphql-tag';

export default {
  menuData: gql`query {
    getMenuData{
      assessments,
      applications,
      requests,
      zoomConnected,
      subjectApplications,
      subjectPercentages,
    }
  }`,
  search: gql`query searchQuery($through: [String], $text: String, $exempt: [String]) {
    search(through: $through, text: $text, exempt: $exempt){
        id, text, through, actualId, extraData
    }
  }`,
  changePassword: gql`mutation changePasswordQuery(
    $dob: String,
    $email: String,
    $newPassword: String
  ) {
    changePassword(newPassword: $newPassword, dob: $dob, email: $email){
        status
    }
  }`,
  updateUserStatus: gql`mutation updateUserStatusQuery(
    $userId: Int,
    $status: String,
    $role: String,
  ) {
    updateUserStatus(userId: $userId, status: $status, role: $role){
        status
    }
  }`,
  changePasswordInApp: gql`mutation updatePasswordQuery($password: String, $newPassword: String) {
    updatePassword(password: $password, newPassword: $newPassword) {
      status
    }
  }`
};
