import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { themes } from './themes'

export const ThemeContext = React.createContext()

function ThemeContextProvider({ children }) {
  const theme = useMemo(() => themes.baseTheme, [])
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

ThemeContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ThemeContextProvider
