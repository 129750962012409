import PropTypes from 'prop-types'
import React from 'react'

function JitsuError({ children, className }) {
  return <p className={`text-red-500 text-xs ${className}`}>{children}</p>
}

JitsuError.defaultProps = {
  children: '',
  className: '',
}

JitsuError.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default JitsuError
