import PropTypes from 'prop-types'
import React from 'react'

function JitsuField({ error = '', children, className, id, hint }) {
  return (
    <div className={`mb-2 ${className} w-full`} id={id}>
      {children}
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      {hint && <p className="text-gray-400 text-xs mt-1">{hint}</p>}
    </div>
  )
}

JitsuField.defaultProps = {
  children: <div>Element Needed</div>,
  error: '',
  className: '',
  id: '',
  hint: '',
}

JitsuField.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  hint: PropTypes.string,
}

export default JitsuField
