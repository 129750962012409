import PropTypes from 'prop-types';
import React from 'react';

function JitsuHeader({ size, children, className }) {
  const Tag = `h${size}`;
  return <Tag className={ `${className} font-bold` }>
    { children }
  </Tag>;
}

JitsuHeader.defaultProps = {
  children: 'header',
  className: '',
  size: 3
};

JitsuHeader.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default JitsuHeader;
