import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Routes from './routes';
import ac3Client from './setup/ac3client';
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(<ApolloProvider client={ ac3Client }>
  <Routes/>
</ApolloProvider>);
