import PropTypes from 'prop-types';
import React from 'react';

function JitsuHint({ children, className }) {
  return <div className={ `${className} text-gray-500 italic text-sm` }>
  { children }
</div>
}

JitsuHint.defaultProps = {
  children: 'Expecting HINTS!!!',
  className: ''
};

JitsuHint.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired
};

export default JitsuHint;
