import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewParent = lazy(() => import('../views/ParentPage/ViewParent'));
const CreateParent = lazy(() => import('../views/ParentPage/CreateParent'));
const EditParent = lazy(() => import('../views/ParentPage/EditParent'));
const DeleteParent = lazy(() => import('../views/ParentPage/DeleteParent'));
const ParentPage = lazy(() => import('../views/ParentPage/Parents'));

function ParentRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateParent/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><ParentPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewParent/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteParent/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditParent/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default ParentRoutes;
