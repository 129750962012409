import PropTypes from 'prop-types'
import React from 'react'
import useAppTheme from '../hooks/useAppTheme'

function JitsuTabBody({
  onScreen,
  children,
  bordered,
  height,
  overflow,
  shadow,
  bg,
}) {
  const { borderPrimary200 } = useAppTheme()
  return (
    <div
      className={`${height} ${onScreen ? 'block' : 'hidden'} ${shadow} mt-1 ${
        bordered ? `border-t ${borderPrimary200}` : ''
      }
      ${overflow} ${bg ?? 'bg-white'}`}
    >
      {children}
    </div>
  )
}

JitsuTabBody.defaultProps = {
  className: '',
  children: <></>,
  bordered: false,
  overflow: 'overflow-auto',
  height: 'h-full',
  shadow: 'shadow-sm',
}

JitsuTabBody.propTypes = {
  onScreen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  bordered: PropTypes.bool.isRequired,
  overflow: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  shadow: PropTypes.string.isRequired,
  bg: PropTypes.string,
}

export default JitsuTabBody
