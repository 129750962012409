import {
  format,
  differenceInSeconds,
  isValid,
  startOfMonth,
  subMonths,
  endOfMonth,
} from 'date-fns'

export const isNumber = (val) => !isNaN(parseFloat(val)) && isFinite(val) // eslint-disable-line

export const DATE_FORMATS = {
  MONTH_NAME_YEAR: 'MMMM yyyy',
  MONTH_NAME: 'MMMM',
  MONTH_SHORT_NAME: 'MMM',
  MONTH: 'MM',
  YEAR_MONTH_DAY: 'yyyy-MM-dd',
  YEAR_MONTH_DAY_HOUR_MINUTE: 'yyyy-MM-dd hh:mm a',
  YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS: 'yyyy-MM-dd HH:mm:ss',
  HOUR_MINUTE: 'hh:mm a',
  HOUR_MINUTE_ONLY: 'HH:mm',
  DAY_MONTH_YEAR_HOUR_MINUTE: 'dd-MM-yyyy hh:mm a',
  DAY_NAME: 'E',
  DAY: 'dd',
}

export const formatDate = (
  date,
  formatString = DATE_FORMATS.YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS,
) => {
  try {
    if (isNumber(date)) {
      return format(parseInt(date, 10), formatString)
    }
    return format(new Date(date), formatString)
  } catch (e) {
    return date
  }
}

export const changeDateToReadableTime = (date) =>
  formatDate(date, DATE_FORMATS.YEAR_MONTH_DAY_HOUR_MINUTE)

export const changeDateFromServer = (
  serverDate,
  dateFormat = DATE_FORMATS.YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS,
) => formatDate(serverDate, dateFormat)

export const changeMilitaryTimeToNormalTime = (time) =>
  formatDate(`2020-01-01 ${time}`, DATE_FORMATS.HOUR_MINUTE)

export const getDifferenceInSeconds = (date) => {
  const formattedDate = formatDate(
    date,
    DATE_FORMATS.YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS,
  )
  return differenceInSeconds(new Date(formattedDate), new Date())
}

export const inThePast = (date) => getDifferenceInSeconds(date) < 0

export const inTheFutureOrNow = (date) => getDifferenceInSeconds(date) >= 0

export const inTheFuture = (date) => getDifferenceInSeconds(date) > 0

export const inThePastWithTimestamp = (timestamp) => inThePast(timestamp)

export const changePeriodsToEvents = (periods) =>
  periods.map((period) => ({
    ...period,
    startDate: new Date(`${period.date} ${period.startTime}`),
    endDate: new Date(`${period.date} ${period.endTime}`),
    date: new Date(`${period.date} ${period.startTime}`),
    title: `${period.subject ? `${period.subject.code} - ` : ''}${period.name}`,
  }))

export const validateDateFormatAsyyyyMMDD = (dob) => {
  const [year, month, day] = dob.split('-')
  return !(
    year === undefined ||
    month === undefined ||
    day === undefined ||
    Number.isNaN(year) ||
    Number.isNaN(month) ||
    Number.isNaN(day) ||
    !isValid(new Date(dob))
  )
}

export const getLastMonthsDateMargins = (limit = 12) => {
  const today = new Date()
  const dateMargins = []
  const preferredFormat = `${DATE_FORMATS.YEAR_MONTH_DAY} ${DATE_FORMATS.HOUR_MINUTE_ONLY}`
  // eslint-disable-next-line prefer-spread
  Array.apply(null, Array(limit)).forEach((_, index) => {
    const start = format(startOfMonth(subMonths(today, index)), preferredFormat)
    const end = format(endOfMonth(subMonths(today, index)), preferredFormat)
    dateMargins.push({ end, start })
  })
  return dateMargins
}
