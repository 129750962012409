import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import { createUploadLink } from 'apollo-upload-client'
import * as hosts from '../constants/api'
import { deleteToken, getToken } from '../functions/user'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  defaultIntegrations: [new Sentry.Integrations.Breadcrumbs({ fetch: false })],
})

function getUri() {
  return `${hosts.BACKEND}/graphql`
}

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `JWT ${getToken()}`,
    },
  })
  return forward(operation)
})

const httpLink = createUploadLink({
  uri: getUri(),
})

const newErrorLink = onError((error) => {
  const { networkError, graphQLErrors, operation } = error

  if (
    graphQLErrors &&
    graphQLErrors.some(
      (err) => err.extensions && err.extensions.code === 'FORBIDDEN',
    )
  ) {
    deleteToken()
    return
  }
  if (graphQLErrors) graphQLErrors.forEach(() => {})
  if (
    networkError &&
    operation.getContext().response &&
    operation.getContext().response.status === 401
  ) {
    deleteToken()
    return
  }
  if (
    networkError &&
    operation.getContext().response &&
    operation.getContext().response.status === 400
  ) {
    // eslint-disable-next-line no-console
    console.error(networkError)
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAssessments: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getNotes: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedSubjects: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedClasses: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getAllNews: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedColleges: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedQuestions: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedStudents: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedTeachers: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedGrades: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedInvoices: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedParentStudentRemarks: {
          ...relayStylePagination(),
          keyArgs: ['pagination', 'studentId'],
        },
        getPaginatedTeacherStudentRemarks: {
          ...relayStylePagination(),
          keyArgs: ['pagination', 'studentId'],
        },
        getPaginatedStudentPaymentRecordsForInvoice: {
          ...relayStylePagination(),
          keyArgs: ['pagination', 'invoiceId'],
        },
        getPaginatedInvoicePaymentRecordsForStudent: {
          ...relayStylePagination(),
          keyArgs: ['pagination', 'studentId'],
        },
        getPaginatedPayments: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
        getPaginatedComments: {
          ...relayStylePagination(),
          keyArgs: ['pagination'],
        },
      },
    },
  },
})
const ac3Client = new ApolloClient({
  link: ApolloLink.from([
    // sentryLink,
    newErrorLink,
    middlewareAuthLink,
    httpLink,
  ]),
  cache,
})

export default ac3Client
