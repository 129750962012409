import * as Sentry from '@sentry/react'
import { addMonths, endOfMonth, isBefore, startOfMonth } from 'date-fns'
import { BACKEND } from '../constants/api'
import {
  permissionLevels,
  permissions,
  tabPermissions,
} from '../constants/permissions'
import {
  ADMIN,
  OVERSEER,
  PARENT,
  STUDENT,
  SUBJECT_ADMIN,
  TEACHER,
} from '../constants/roles'
import { DATE_FORMATS, formatDate } from './date'
import { deleteToken, getCurrentUser } from './user'

export const getMonthData = (date, isFirstMonth, isLastMonth) => ({
  name: formatDate(date, DATE_FORMATS.MONTH_NAME),
  startDate: isFirstMonth
    ? formatDate(date, DATE_FORMATS.YEAR_MONTH_DAY)
    : formatDate(startOfMonth(date), DATE_FORMATS.YEAR_MONTH_DAY),
  endDate: isLastMonth
    ? formatDate(date, DATE_FORMATS.YEAR_MONTH_DAY)
    : formatDate(endOfMonth(date), DATE_FORMATS.YEAR_MONTH_DAY),
})

export const getSemesterStartAndEndDates = () => {
  const { semesterEndDate, semesterStartDate } = getCurrentUser()
  return {
    startDate: formatDate(semesterStartDate, DATE_FORMATS.YEAR_MONTH_DAY),
    endDate: formatDate(semesterEndDate, DATE_FORMATS.YEAR_MONTH_DAY),
  }
}

export const getCurrentSemesterMonths = () => {
  const { semesterEndDate, semesterStartDate } = getCurrentUser()
  const startMonth = getMonthData(semesterStartDate, true)
  const lastMonth = getMonthData(semesterEndDate, false, true)

  let semesterMonths = [startMonth]
  const lastMonthDate = lastMonth.startDate

  let counter = 0
  while (
    isBefore(semesterMonths[counter].startDate, lastMonthDate) &&
    counter < 12
  ) {
    semesterMonths = [
      ...semesterMonths,
      getMonthData(addMonths(semesterMonths[counter].startDate, 1)),
    ]
    counter += 1
  }
  return [
    {
      name: 'All',
      startDate: startMonth.startDate,
      endDate: lastMonth.endDate,
    },
    ...semesterMonths,
  ]
}

export const handleRandomDateFormats = (date, preferredFormat) =>
  formatDate(date, preferredFormat || DATE_FORMATS.YEAR_MONTH_DAY)

export const createMap = (arr, key) =>
  arr.reduce((acc, curr) => ({ ...acc, [curr[key]]: curr }), {})

export const goToWebsite = (website) => {
  window.location.href = website
}

export const isNumber = (val) => !isNaN(parseFloat(val)) && isFinite(val) // eslint-disable-line

export const isInteger = (val) => Number.isInteger(val) // eslint-disable-line

export const sortArray = (array, property, descending) =>
  property === '' || property === undefined
    ? array
    : array.concat().sort((a, b) => {
        const currentElement = isNumber(a[property])
          ? a[property]
          : a[property].toUpperCase()
        const nextElement = isNumber(a[property])
          ? b[property]
          : b[property].toUpperCase()
        if (currentElement < nextElement) return descending ? 1 : -1
        if (currentElement > nextElement) return descending ? -1 : 1
        return 0
      })

export const passwordChangeKey = {
  [ADMIN]: 'updateTeacherPassword',
  [TEACHER]: 'updateTeacherPassword',
  [STUDENT]: 'updateStudentPassword',
  [PARENT]: 'updateParentPassword',
}

export const isPassportChanged = (response, role) =>
  response[passwordChangeKey[role]].token !== null

export const createDownloadLink = (url) => `${BACKEND}/files${url}`

export const getFilename = (url) => url.split('/').pop()

// eslint-disable-next-line no-console
export const logError = (error) => console.error(error)

export const pageAuthorized = (name) => {
  const role = getCurrentUser().userRole
  if (!role) return true
  return permissions[name] && permissions[name][getCurrentUser().userRole]
    ? permissions[name][getCurrentUser().userRole].includes(
        permissionLevels.PAGE,
      )
    : false
}

export const tabAuthorized = (tabName, page, ignorePermission) => {
  const role = getCurrentUser().userRole
  if (ignorePermission) return true
  if (!role || !tabPermissions[page]) return true
  return tabPermissions[page][role].includes(tabName)
}

export const tabsAuthorized = (tabs = [], page) =>
  tabs.filter((tab) => tabAuthorized(tab.value, page, tab.ignorePermission))

export const selectSlot = (navigate, { userRole }) => {
  if ([ADMIN, TEACHER, OVERSEER, SUBJECT_ADMIN].includes(userRole)) {
    navigate('/periods/create')
  }
}

export const forcefullyExtractId = () =>
  parseInt(window.location.pathname.split('/')[2] || 0, 10)

export const captureError = (error) => {
  if (!['production', 'PRODUCTION', 'PROD'].includes(process.env.NODE_ENV))
    return
  Sentry.captureException(error)
}

export const handleError = (error, callback = () => {}) => {
  if (error.status === 401) {
    deleteToken()
    return
  }
  captureError(error)
  callback()
}

export const classNames = (...classes) => classes.filter(Boolean).join(' ')

export function numberWithCommas(x) {
  return (x || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatCurrencyNumbers = (amount) => {
  if (amount >= 1000000) {
    return `${((Math.round(amount * 10) / 1000000)
      .toString()
      .match(/^-?\d+(?:\.\d{0,1})?/) || [])[0].replace(/\.0$/, '')}M`
  }
  if (amount >= 10000) {
    return `${((Math.round(amount * 10) / 10000)
      .toString()
      .match(/^-?\d+(?:\.\d{0,1})?/) || [])[0].replace(/\.0$/, '')}K`
  }

  if (amount >= 1000) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return amount.toString()
}

export const trim = (text) => text.replace(/^\s+|\s+$/gm, '')
