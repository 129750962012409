import jwtDecode from 'jwt-decode'

export const getToken = () => localStorage.getItem('token')

export const getCurrentUser = () => (getToken() ? jwtDecode(getToken()) : {})

export const relocateUser = (location) => {
  window.location.href = `${window.location.origin}/${location}`
}

export const relocationUserToInitialUrl = () => {
  const relocateUrl = localStorage.getItem('relocateUrl')
  localStorage.setItem('relocateUrl', '')
  window.location.href = relocateUrl
}

export const logoutUser = () => {
  window.location.href = `${window.location.origin}/`
}

export const deleteToken = () => {
  localStorage.removeItem('token') // eslint-disable-line
  logoutUser()
}
