import gql from 'graphql-tag'

const invoiceFrag = `
  name,
  id,
  code,
  description,
  amount,
  createdAt,
  updatedAt,
  status,
`

export default {
  list: gql`query {
    getInvoices{
      ${invoiceFrag}
    }
  }`,

  paginatedInvoices: gql`query getPaginatedInvoicesQuery(
    $pagination: PaginationInput, $after: String,
  ) {
    getPaginatedInvoices(
      pagination: $pagination,
      after: $after
    ) {
      totalCount,
      pageInfo {
        endCursor,
        startCursor,
        hasNextPage,
      },
      edges {
        node {
          ${invoiceFrag}
          teacherFirstName,
          teacherLastName,
          teacherRegNumber,
          completionStatus
          amountPaid,
          amountExpected
        }
      }
    }
  }`,
  detailedList: gql`query {
    getInvoices{ ${invoiceFrag} }
  }`,
  item: gql`query getInvoiceQuery($id: Int) {
    getInvoice(id: $id){
      ${invoiceFrag}
      teacher{
        id, firstName, lastName
      }
      paymentSubAccount{
        id, name, accountNumber, subAccountId
      }
    }
  }`,
  invoiceStatus: gql`
    query getInvoiceStatusQuery($id: Int) {
      getInvoiceStatus(id: $id)
    }
  `,
  isInvoiceInvalidable: gql`
    query getIsInvoiceInvalidableQuery($invoiceId: Int) {
      getIsInvoiceInvalidable(invoiceId: $invoiceId)
    }
  `,

  update: gql`mutation updateInvoiceQuery(
    $id: Int,
    $name: String!,
    $description: String!,
    $code: String!,
    $amount: Float!) {
      updateInvoice(
        id: $id,
        name: $name,
        code: $code,
        description: $description,
        amount: $amount){
          ${invoiceFrag}
          teacher{
            id, firstName, lastName
          }
          paymentSubAccount{
            id, name, accountNumber, subAccountId
          }
        }
    }
  `,
  create: gql`mutation createInvoiceQuery(
    $name: String!,
    $description: String!,
    $code: String!,
    $classesIds: [Int],
    $studentsIds: [Int],
    $paymentSubAccountId: Int,
    $amount: Float!) {
      createInvoice(
        name: $name,
        description: $description,
        studentsIds: $studentsIds,
        classesIds: $classesIds,
        paymentSubAccountId: $paymentSubAccountId
        amount: $amount,
        code: $code){
          ${invoiceFrag}
        }
    }
  `,
  wipe: gql`
    mutation deleteInvoiceQuery($id: Int) {
      deleteInvoice(id: $id) {
        id
      }
    }
  `,

  freezeInvoice: gql`mutation freezeInvoiceQuery($invoiceId: Int) {
    freezeInvoice(invoiceId: $invoiceId){
      ${invoiceFrag}
    }
  }`,

  unfreezeInvoice: gql`mutation unfreezeInvoiceQuery($invoiceId: Int) {
    unfreezeInvoice(invoiceId: $invoiceId){
      ${invoiceFrag}
    }
  }`,

  invalidateInvoice: gql`mutation invalidateInvoiceQuery($invoiceId: Int) {
    invalidateInvoice(invoiceId: $invoiceId){
      ${invoiceFrag}
    }
  }`,

  getStudentPaymentRecordsForInvoice: gql`
    query getStudentPaymentRecordsForInvoiceQuery($invoiceId: Int) {
      getStudentPaymentRecordsForInvoice(invoiceId: $invoiceId) {
        id
        firstName
        lastName
        paymentRecords {
          amount
          createdAt
        }
      }
    }
  `,

  paginatedStudentPaymentRecordsForInvoice: gql`
    query getPaginatedStudentPaymentRecordsForInvoiceQuery(
      $pagination: PaginationInput
      $invoiceId: Int
      $after: String
    ) {
      getPaginatedStudentPaymentRecordsForInvoice(
        pagination: $pagination
        invoiceId: $invoiceId
        after: $after
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            studentId
            invoiceId
            firstName
            lastName
            regNumber
            amount
            name
            code
            invoiceId
            studentId
            paymentRecords {
              amount
              createdAt
              identificationCode
              method
              parentId
              parentFirstName
              parentRegNumber
              parentLastName
            }
          }
        }
      }
    }
  `,

  paginatedInvoicePaymentRecordsForStudent: gql`
    query getPaginatedInvoicePaymentRecordsForStudentQuery(
      $pagination: PaginationInput
      $studentId: Int
      $after: String
    ) {
      getPaginatedInvoicePaymentRecordsForStudent(
        pagination: $pagination
        studentId: $studentId
        after: $after
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            studentId
            invoiceId
            firstName
            lastName
            amount
            invoiceId
            name
            code
            studentId
            paymentRecords {
              amount
              createdAt
              identificationCode
              method
              parentId
              parentFirstName
              parentRegNumber
              parentLastName
            }
          }
        }
      }
    }
  `,

  recordPayment: gql`
    mutation recordPaymentQuery(
      $studentId: Int
      $invoiceId: Int
      $identificationCode: String
      $transactionFee: Float
      $serviceFee: Float
      $method: String
      $amount: Float
    ) {
      recordPayment(
        studentId: $studentId
        invoiceId: $invoiceId
        identificationCode: $identificationCode
        serviceFee: $serviceFee
        transactionFee: $transactionFee
        method: $method
        amount: $amount
      ) {
        id
        studentId
        invoiceId
        firstName
        lastName
        paymentRecords {
          amount
          createdAt
          identificationCode
          method
          serviceFee
          transactionFee
          parentId
          parentFirstName
          parentRegNumber
          parentLastName
        }
      }
    }
  `,
  schoolInvoiceSummary: gql`
    query getSchoolInvoiceSummaryQuery {
      getSchoolInvoiceSummary {
        expected
        received
        owed
      }
    }
  `,
  methodsInvoiceSummary: gql`
    query getMethodsInvoiceSummariesQuery {
      getMethodsInvoiceSummaries {
        method
        amount
      }
    }
  `,
  periodsInvoiceSummary: gql`
    query getPeriodsInvoiceSummariesQuery($periods: [DateMarginsInput]) {
      getPeriodsInvoiceSummaries(periods: $periods) {
        period
        amount
      }
    }
  `,
  paginatedPayments: gql`
    query getPaginatedPaymentsQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedPayments(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            studentId
            invoiceId
            teacherId
            studentFirstName
            studentLastName
            studentRegNumber
            teacherFirstName
            teacherRegNumber
            teacherLastName
            parentId
            parentFirstName
            parentRegNumber
            parentLastName
            invoiceName
            invoiceCode
            amount
            createdAt
            identificationCode
            method
            transactionFee
            serviceFee
          }
        }
      }
    }
  `,
}
