/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { createContext, useMemo, useReducer } from 'react';
import { NOTIFICATION_ACTION_TYPES } from './helpers';
import { CRITICAL, SUCCESS } from '../../constants/notificationTypes';

export const NotificationContext = createContext();

const initialState = {
  notifications: []
};

const ACTION_HANDLERS = {
  [NOTIFICATION_ACTION_TYPES.ADD_NOTIFICATION]: (state, notification) => ({
    ...state,
    notifications: [...state.notifications, notification]
  }),
  [NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATION]: (state) => ({
    ...state,
    notifications: []
  })
};

const reducer = (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action.payload) : state;
};

function NotificationContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const notifyError = (text) => {
    const notificationText = text && typeof text === 'string'
      ? text
      : 'We encountered a problem processing your request. Please try again.'
    dispatch({
      type: NOTIFICATION_ACTION_TYPES.ADD_NOTIFICATION,
      payload: { type: CRITICAL, text: notificationText }
    });
  };

  const notifySuccess = (text = 'Your request was successfully processed.') => {
    dispatch({
      type: NOTIFICATION_ACTION_TYPES.ADD_NOTIFICATION,
      payload: { type: SUCCESS, text }
    });
  };

  const value = useMemo(() => [{ notifyError, notifySuccess }, state, dispatch], [state])

  return (
    <NotificationContext.Provider value={ value }>
      { props.children }
    </NotificationContext.Provider>
  );
}

NotificationContextProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default NotificationContextProvider;
