import React, { Fragment, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import PAGES from '../constants/pages'
import { ADMIN, STUDENT, TEACHER } from '../constants/roles'
import { getCurrentUser } from '../functions/user'
import SuspenseComponent from '../components/SuspenseComponent'

const Dashboard = lazy(() => import('../views/Dashboard/DashboardPage'))
const Transcript = lazy(() => import('../views/TranscriptPage'))
const Profile = lazy(() => import('../views/ProfilePage'))
const SubjectRegistration = lazy(() => import('../views/RegistrationPage'))
const PerformanceAndAttendance = lazy(() =>
  import('../views/StudentPage/PerformanceAndAttendance'),
)
const TeacherPerformance = lazy(() =>
  import('../views/PerformancePage/TeacherPerformance'),
)
const RequestsHandler = lazy(() => import('../views/RequestManagementPage'))
const Settings = lazy(() => import('../views/SettingsPage'))

function RoutesUnderTopBar() {
  const user = getCurrentUser()
  return user.userId ? (
    <>
      <Routes>
        <Route
          path={PAGES.dashboard}
          element={
            <SuspenseComponent>
              <Dashboard />
            </SuspenseComponent>
          }
        />
        <Route
          path={PAGES.profile}
          element={
            <SuspenseComponent>
              <Profile />
            </SuspenseComponent>
          }
        />
        <Route
          path={PAGES.settings}
          element={
            <SuspenseComponent>
              <Settings />
            </SuspenseComponent>
          }
        />
        <Route
          path={PAGES.requests}
          element={
            <SuspenseComponent>
              <RequestsHandler />
            </SuspenseComponent>
          }
        />
        <Route
          path={PAGES.transcript}
          element={
            <SuspenseComponent>
              <Transcript />
            </SuspenseComponent>
          }
        />
        <Route
          path={PAGES.registration}
          element={
            <SuspenseComponent>
              <SubjectRegistration studentId={user.userId} />
            </SuspenseComponent>
          }
        />
        {user.userRole === STUDENT && (
          <Route
            path={PAGES.performance}
            element={
              <SuspenseComponent>
                <PerformanceAndAttendance userId={user.userId} user={user} />
              </SuspenseComponent>
            }
          />
        )}

        {(user.userRole === ADMIN || user.userRole === TEACHER) && (
          <Route
            path={PAGES.performance}
            element={
              <SuspenseComponent>
                <TeacherPerformance userId={user.userId} />
              </SuspenseComponent>
            }
          />
        )}
      </Routes>
    </>
  ) : (
    <></>
  )
}

export default RoutesUnderTopBar
