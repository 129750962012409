import PropTypes from 'prop-types';
import React from 'react';
import JitsuIcon from './JitsuIcon';

function JitsuMultiselectToggle({ onClick, expanded, disabled, id }) {
  const arrowUpClass = expanded ? 'hidden' : 'block';
  const arrowDownClass = expanded ? 'block' : 'hidden';
  return <div
    role="button"
    id={ `${id}_toggle` }
    className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
    onClick={ onClick }
  >
    <button type="button" className={ `cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none ${arrowUpClass}` }>
      <JitsuIcon name={ `${disabled ? 'lock' : 'arrow-up'}` } className="h-4 w-4"/>
    </button>
    <button type="button" className={ `cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none ${arrowDownClass}` }>
      <JitsuIcon name={ `${disabled ? 'lock' : 'arrow-down'}` } className="h-4 w-4"/>
    </button>
  </div>;
}

JitsuMultiselectToggle.defaultProps = {
  disabled: false,
  id: ''
};

JitsuMultiselectToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default JitsuMultiselectToggle;
