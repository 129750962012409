import React from 'react'
import PropTypes from 'prop-types'
import useAppTheme from '../../hooks/useAppTheme'
import { FolderOpenIconOutline, PlusIconSolid } from '../../assets/icons'

function EmptyState({ onClick, headerText, subText, buttonText }) {
  const {
    textPrimary200,
    bgPrimary600,
    hoverBgPrimary700,
    focusRingPrimary500,
  } = useAppTheme()
  return (
    <div className="text-center border-2 border-gray-300 border-dashed p-6 rounded">
      <div className="flex justify-center w-full">
        <FolderOpenIconOutline
          className={`-ml-1 mr-2 h-20 w-20 ${textPrimary200} font-light flex-shrink-0`}
        />
      </div>
      <h3 className="mt-2 text-md font-medium text-gray-600">{headerText}</h3>
      <p className="mt-1 text-sm text-gray-500">{subText}</p>
      {onClick && (
        <div className="mt-6">
          <button
            onClick={onClick}
            type="button"
            className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md 
          text-white ${bgPrimary600} ${hoverBgPrimary700} focus:outline-none focus:ring-1 focus:ring-offset-1 ${focusRingPrimary500}`}
          >
            <PlusIconSolid className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {buttonText}
          </button>
        </div>
      )}
    </div>
  )
}

EmptyState.defaultProps = {
  headerText: 'No results',
  subText: 'We could not find any results for your search',
  buttonText: 'New project',
}

EmptyState.propTypes = {
  headerText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default EmptyState
