import PropTypes from 'prop-types';
import React, { Fragment, Suspense } from 'react';

function SuspenseComponent({ children, Fallback }) {
  return <Suspense fallback={ <Fallback/> }>
  { children }
</Suspense>
}

SuspenseComponent.defaultProps = {
  // eslint-disable-next-line react/display-name
  Fallback: () => <></>
};

SuspenseComponent.propTypes = {
  children: PropTypes.any.isRequired,
  Fallback: PropTypes.func.isRequired
};

export default SuspenseComponent;
