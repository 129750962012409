import PropTypes from 'prop-types'
import React from 'react'
import JitsuField from './JitsuField'
import JitsuLabel from './JitsuLabel'

function JitsuInput({
  value,
  onChange,
  label,
  placeholder,
  name,
  error,
  max,
  type,
  onEnter,
  tooltip,
  size,
  id,
  required,
  disabled,
  capitalized,
  optional,
  InputIcon,
  wrapperClassName,
  hint,
}) {
  const onKeyUp = (e) => {
    if (onEnter && e.keyCode === 13) onEnter(value, name)
  }

  return (
    <JitsuField
      error={error}
      className={`${wrapperClassName} relative`}
      hint={hint}
    >
      <JitsuLabel
        forValue="name"
        optional={optional}
        required={required}
        tooltip={tooltip}
      >
        {label}

        {max && (
          <div className="text-gray-500 text-xs absolute right-0">
            {max - value.length}
          </div>
        )}
      </JitsuLabel>
      <input type="hidden" value="prayer" name={name} />
      <div className="mt-1 mb-6 relative rounded-md shadow-sm">
        {InputIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <InputIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <input
          id={id || name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) =>
            onChange(
              capitalized ? e.target.value.toUpperCase() : e.target.value,
              name,
            )
          }
          name={name}
          maxLength={max}
          size={size}
          disabled={disabled}
          autoComplete="off"
          className={`focus:ring-indigo-500 focus:border-indigo-500 ${
            disabled ? 'text-gray-400' : 'text-gray-800'
          }
        block w-full ${
          InputIcon ? 'pl-10' : ''
        } text-xs border-gray-300 rounded-md`}
          onKeyUp={onKeyUp}
        />
      </div>
    </JitsuField>
  )
}

JitsuInput.defaultProps = {
  type: 'text',
  value: '',
  label: '',
  id: '',
  placeholder: '',
  onChange: () => {},
  className: '',
  wrapperClassName: '',
  required: false,
  optional: false,
  size: '',
  hint: '',
}

JitsuInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  max: PropTypes.any,
  size: PropTypes.any,
  type: PropTypes.string.isRequired,
  onEnter: PropTypes.func,
  required: PropTypes.bool.isRequired,
  optional: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  hint: PropTypes.string,
  tooltip: PropTypes.any,
  capitalized: PropTypes.bool,
  InputIcon: PropTypes.any,
}

export default JitsuInput
