import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import JitsuInput from '../../components-elements/JitsuInput'
import JitsuMultiselectOption from '../../components-elements/JitsuMultiselectOption'
import others from '../../queries/others'

const withSearch = ({
  Comp,
  through,
  exempt,
  id,
  createProps = () => ({}),
}) => {
  function Search(props) {
    const [searchResult, setSearchResult] = useState([])
    const [value, setValue] = useState('')
    const [timer, setTimer] = useState(null)

    const onChangeValue = (val) => {
      setValue(val)
    }

    const setResults = (result) => {
      setSearchResult(result)
      setTimer(null)
    }

    const { data, loading, error } = useQuery(others.search, {
      variables: {
        through,
        text: value,
        exempt,
      },
    })

    useEffect(() => () => clearTimeout(timer), [timer])

    useEffect(() => {
      if (loading || error) return
      setResults(data.search)
    }, [data, loading, error])

    return (
      <Comp {...props} {...createProps({ searchResult })}>
        <JitsuMultiselectOption searcher>
          <div className="w-full flex justify-center">
            <JitsuInput
              value={value}
              placeholder="Search"
              onChange={onChangeValue}
              name="value"
              id={`${id}-search-value`}
            />
          </div>
        </JitsuMultiselectOption>
      </Comp>
    )
  }

  return Search
}

export default withSearch
