/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { classNames } from '../functions'
import useAppTheme from '../hooks/useAppTheme'
import { CheckIconSolid, ChevronUpDownIconSolid } from '../assets/icons'
import JitsuLabel from './JitsuLabel'

function JitsuSelect({
  label,
  id,
  value,
  onChange,
  defaultOption,
  error,
  name,
  options = [],
  optional,
  required,
  tooltip,
  ignoreFirstOption,
  disabled,
}) {
  const theme = useAppTheme()
  const allOptions = ignoreFirstOption
    ? options
    : [defaultOption, ...(options || [])]
  const selected = allOptions.find((option) => option.value === value)
  const onSelect = (selectedOption) => {
    onChange(selectedOption, name)
  }
  return (
    <>
      <Listbox
        value={selected}
        onChange={onSelect}
        disabled={disabled}
        name={name}
      >
        {({ open }) => (
          <>
            <Listbox.Label
              className={`block text-xs font-medium ${theme.textSecondary}`}
            >
              <JitsuLabel
                forValue="name"
                optional={optional}
                required={required}
                tooltip={tooltip}
              >
                {label}
              </JitsuLabel>
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button
                className={`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 
              text-left cursor-default focus:outline-none focus:ring-1 ${theme.focusRingPrimary500} ${theme.focusBorderPrimary500} text-xs`}
              >
                <span className="block truncate">{selected?.text || ''}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIconSolid
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black 
                  ring-opacity-5 overflow-auto focus:outline-none text-xs"
                >
                  {allOptions.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active
                            ? `text-white ${theme.bgPrimary600}`
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9 text-xs',
                        )
                      }
                      value={option.value}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {option.text}{' '}
                            {option.subLabel && (
                              <span className="mr-2 text-xs text-gray-400">
                                {option.subLabel}
                              </span>
                            )}
                          </span>

                          {option.value === value ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : `${theme.bgText600}`,
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIconSolid
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </>
  )
}

JitsuSelect.defaultProps = {
  value: '',
  defaultOption: { text: 'Select an option', value: '' },
  ignoreFirstOption: false,
  classname: '',
  wrapperClassname: '',
  disabled: false,
  valueKey: 'value',
}

JitsuSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.object.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  tooltip: PropTypes.any,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  ignoreFirstOption: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default JitsuSelect
