import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import JitsuField from './JitsuField'
import JitsuLabel from './JitsuLabel'
import useAppTheme from '../hooks/useAppTheme'
import { PaperClipIconOutline } from '../assets/icons'

const isFileSet = (value) => {
  if (value && value.size > 0) {
    return true
  }
  return (
    value && !!Object.keys(value).length && value !== '' && value.size !== -1
  )
}

const getFileName = (value) => {
  if (typeof value === 'string') {
    return value
  }
  return value.name || ''
}

function JitsuUpload({
  name,
  fileTypes,
  value = {},
  label,
  wrapperClassname,
  error,
  id,
  optional,
  required,
  tooltip,
  onChange,
}) {
  const uploadRef = useRef('upload')
  const { bgPrimary100, textPrimary } = useAppTheme()
  const onClick = () => {
    uploadRef.current.click()
  }

  const handleChange = (e) => {
    onChange(e.target.files[0], name)
  }
  const removeFile = () => {
    onChange({ size: -1, path: null }, name)
  }

  const val = value || ''

  return (
    <JitsuField className={`${wrapperClassname}`} error={error} id={id}>
      {label && (
        <JitsuLabel optional={optional} required={required} tooltip={tooltip}>
          {label}
        </JitsuLabel>
      )}
      <div className="border rounded relative">
        <button
          type="button"
          onClick={onClick}
          className={`inline-block px-6 py-2.5 ${bgPrimary100} ${textPrimary} font-medium 
        text-xs leading-tight uppercase flex w-full justify-center`}
        >
          <PaperClipIconOutline
            className="-ml-0.5 mr-2 h-4 w-4"
            aria-hidden="true"
          />
          {getFileName(val) || 'Upload from computer'}
        </button>
        {isFileSet(val) && (
          <button
            type="button"
            onClick={removeFile}
            className="inline-block py-1 px-2 text-red-600 font-medium rounded-full top-0 
            text-lg leading-tight uppercase absolute right-0"
          >
            x
          </button>
        )}
      </div>
      <div className="flex">
        <input
          type="file"
          accept={fileTypes.toString()}
          required
          onChange={handleChange}
          name={name}
          id={name}
          ref={uploadRef}
          className="hidden"
        />
      </div>
    </JitsuField>
  )
}

JitsuUpload.defaultProps = {
  fileTypes: [
    '.pdf',
    '.doc',
    '.docx',
    '.png',
    '.jpg',
    '.jpeg',
    '.ppt',
    '.csv',
    '.xlsx',
  ],
  placeholder: 'Select a file from desktop',
  value: {},
  classname: '',
  id: '',
  wrapperClassname: '',
}

JitsuUpload.propTypes = {
  value: PropTypes.any,
  id: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  fileTypes: PropTypes.array,
  wrapperClassname: PropTypes.string,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
}

export default JitsuUpload
