import gql from 'graphql-tag'

const paymentSubAccountFrag = `
  subAccountId,
  name,
  status,
  createdAt,
  updatedAt,
  accountNumber,
  accountBank,
  accountName,
  teacher { firstName, lastName, id, regNumber }
`

export default {
  list: gql`query {
    getPaymentSubAccounts{
      id,
      ${paymentSubAccountFrag}
    }
  }`,
  item: gql`
    query getPaymentSubAccountQuery($id: Int) {
      getPaymentSubAccount(id: $id) {
        id,
        ${paymentSubAccountFrag}
      }
    }
  `,
  create: gql`
    mutation createPaymentSubAccountQuery(
      $accountName: String!
      $accountNumber: String!
      $accountMobileNumber: String!
      $accountBank: String!
      $accountEmail: String!
      $name: String!
    ) {
      createPaymentSubAccount(
        accountName: $accountName
        accountNumber: $accountNumber
        accountMobileNumber: $accountMobileNumber
        accountBank: $accountBank
        accountEmail: $accountEmail
        name: $name
      ) {
        id,
        ${paymentSubAccountFrag}
      }
    }
  `,
  update: gql`
    mutation updatePaymentSubAccountQuery(
      $id: Int
      $name: String
    ) {
      updatePaymentSubAccount(
        id: $id,
        name: $name
      ) {
        id,
        ${paymentSubAccountFrag}
      }
    }
  `,
  deactivate: gql`
    mutation deactivatePaymentSubAccountQuery(
      $id: Int
    ) {
      activatePaymentSubAccount(
        id: $id
      ) {
        id,
        ${paymentSubAccountFrag}
      }
    }
  `,
  activate: gql`
    mutation activatePaymentSubAccountQuery(
      $id: Int
    ) {
      activatePaymentSubAccount(
        id: $id
      ) {
        id,
        ${paymentSubAccountFrag}
      }
    }
  `,
}
