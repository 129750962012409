import gql from 'graphql-tag'

export default {
  list: gql`
    query {
      getGrades {
        description
        id
        points
        failed
        code
        bottomMargin
        topMargin
        name
      }
    }
  `,

  paginatedGrades: gql`
    query getPaginatedGradesQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedGrades(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
            points
            failed
            code
            bottomMargin
            topMargin
          }
        }
      }
    }
  `,
  detailedList: gql`
    query {
      getGrades {
        name
        id
        points
        code
        bottomMargin
        topMargin
        failed
      }
    }
  `,
  item: gql`
    query getGradeQuery($id: Int) {
      getGrade(id: $id) {
        name
        id
        points
        code
        bottomMargin
        topMargin
        failed
      }
    }
  `,

  update: gql`
    mutation updateGradeQuery(
      $id: Int
      $name: String!
      $points: Int!
      $failed: Int!
      $code: String!
      $topMargin: Float!
      $bottomMargin: Float!
    ) {
      updateGrade(
        id: $id
        name: $name
        failed: $failed
        points: $points
        code: $code
        topMargin: $topMargin
        bottomMargin: $bottomMargin
      ) {
        name
        id
        points
        code
        failed
        topMargin
        bottomMargin
      }
    }
  `,
  create: gql`
    mutation createGradeQuery(
      $name: String!
      $points: Int!
      $code: String!
      $topMargin: Float!
      $failed: Int!
      $bottomMargin: Float!
    ) {
      createGrade(
        name: $name
        points: $points
        failed: $failed
        topMargin: $topMargin
        code: $code
        bottomMargin: $bottomMargin
      ) {
        id
        name
        points
        code
        failed
        topMargin
        bottomMargin
      }
    }
  `,
  wipe: gql`
    mutation deleteGradeQuery($id: Int) {
      deleteGrade(id: $id) {
        id
      }
    }
  `,
}
