import gql from 'graphql-tag';

export default {
  itemNoQuestions: gql`query getAssessmentQuizQuery($assessmentId: Int, $starting: Int) {
    getAssessmentQuiz(assessmentId: $assessmentId, starting: $starting){
      id,
      type,
      duration,
      description,
      name,
    }
  }`,
  itemWithQuestionsAndAnswers: gql`query getAssessmentQuizQuery($assessmentId: Int, $starting: Int) {
    getAssessmentQuiz(assessmentId: $assessmentId, starting: $starting){
      id,
      duration,
      topic,
      questions{
        id,
        name,
        content,
        type,
        answers{
          id, content
        }
      }
    }
  }`,
  markQuiz: gql`mutation markQuizQuery($questionsAndAnswers: [QuestionAndAnswersInput], $assessmentId: Int) {
    markQuiz(questionsAndAnswers: $questionsAndAnswers, assessmentId: $assessmentId){
        status
    }
  }`,
  answeredQuestions: gql`query getAnsweredQuestionsQuery($studentId: Int, $assessmentId: Int) {
    getAnsweredQuestions(studentId: $studentId, assessmentId: $assessmentId){
      id,
      name,
      content,
      failed,
      answers{
        id, content, chosen, correct
      }
    }
  }`
};
