import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import JitsuButton, {
  BUTTON_TYPES,
} from '../../components-elements/JitsuButton'
import { CheckCircleIconOutline, XCircleIconOutline } from '../../assets/icons'

function Modal({
  onClose,
  loading,
  onAccept,
  children,
  title,
  hideAcceptButton,
  hideCancelButton,
  initiallyOnScreen,
  acceptButtonLabel,
  acceptButtonType,
  cancelButtonType,
  cancelButtonLabel,
  ModalIcon,
  AcceptButtonIcon,
  CancelButtonIcon,
  acceptButtonDisabled,
  hideHeader,
  hideFooter,
}) {
  const [open, setOpen] = useState(initiallyOnScreen)
  const closeModal = () => {
    setOpen(false)
    onClose()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  {!!ModalIcon && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ModalIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 ml-2 text-center sm:mt-0 sm:text-left w-full">
                    {!hideHeader && (
                      <Dialog.Title
                        as="h3"
                        className="text-sm leading-6 font-xs text-gray-700"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    <div className="mt-2">{children}</div>
                  </div>
                </div>
                {!hideFooter && (
                  <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex justify-end flex space-x-4">
                    {!hideCancelButton && (
                      <JitsuButton
                        ButtonIcon={CancelButtonIcon}
                        type={cancelButtonType}
                        onClick={closeModal}
                        value={cancelButtonLabel}
                        disabled={loading}
                      />
                    )}
                    {!hideAcceptButton && (
                      <JitsuButton
                        ButtonIcon={AcceptButtonIcon}
                        type={acceptButtonType}
                        onClick={onAccept}
                        value={acceptButtonLabel}
                        loading={loading}
                        disabled={loading || acceptButtonDisabled}
                      />
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.defaultProps = {
  title: '',
  initiallyOnScreen: false,
  hideAcceptButton: false,
  loading: false,
  cancelButtonType: BUTTON_TYPES.DANGER,
  acceptButtonType: BUTTON_TYPES.NORMAL,
  cancelButtonLabel: 'Cancel',
  acceptButtonLabel: 'Accept',
  AcceptButtonIcon: CheckCircleIconOutline,
  CancelButtonIcon: XCircleIconOutline,
  acceptButtonDisabled: false,
  hideFooter: false,
  hideHeader: false,
  hideCancelButton: false,
}

Modal.propTypes = {
  children: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  initiallyOnScreen: PropTypes.bool.isRequired,
  hideAcceptButton: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  acceptButtonLabel: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  acceptButtonType: PropTypes.string.isRequired,
  cancelButtonType: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  ModalIcon: PropTypes.any,
  AcceptButtonIcon: PropTypes.any,
  CancelButtonIcon: PropTypes.any,
  acceptButtonDisabled: PropTypes.bool.isRequired,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
}

export default Modal
