import { Fragment } from 'react'
import Modal from '../components/Modal'

export const useMultiSelectOptionWrapper = ({
  hasOptionsInModal,
  label,
  expanded,
  onCloseModal,
}) => {
  const Tag = hasOptionsInModal ? Modal : Fragment
  const modalProps = hasOptionsInModal
    ? {
        onClose: onCloseModal,
        title: label,
        initiallyOnScreen: expanded,
        hideCancelButton: true,
        acceptButtonLabel: 'Done',
        onAccept: onCloseModal,
      }
    : {}

  return {
    Tag,
    modalProps,
  }
}
