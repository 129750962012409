import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewInvoice = lazy(() => import('../views/InvoicePage/ViewInvoice'));
const CreateInvoice = lazy(() => import('../views/InvoicePage/CreateInvoice'));
const EditInvoice = lazy(() => import('../views/InvoicePage/EditInvoice'));
const DeleteInvoice = lazy(() => import('../views/InvoicePage/DeleteInvoice'));
const InvoicePage = lazy(() => import('../views/InvoicePage/Invoices'));

function InvoiceRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateInvoice/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><InvoicePage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewInvoice/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteInvoice/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditInvoice/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default InvoiceRoutes;
