import gql from 'graphql-tag'

export default {
  list: gql`
    query {
      getSchools {
        name
        id
        website
        address
        timezone
      }
    }
  `,

  messagingConfig: gql`
    query {
      getSchoolMessagingConfig
    }
  `,

  messagingBalance: gql`
    query {
      getSchoolMessagingBalance
    }
  `,

  setMessagingConfig: gql`
    mutation setSchoolMessagingConfigQuery($messagingConfig: String) {
      setSchoolMessagingConfig(messagingConfig: $messagingConfig)
    }
  `,

  paginatedSchools: gql`
    query getPaginatedSchoolsQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedSchools(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  schoolSummary: gql`
    query getSchoolSummaryQuery($id: Int) {
      getSchoolSummary(id: $id) {
        numberOfAdmins
        numberOfOverseers
        numberOfFemaleTeachers
        numberOfMaleParents
        numberOfFemaleParents
        numberOfMaleStudents
        numberOfFemaleStudents
        numberOfMaleTeachers
        numberOfOverseers
        numberOfActiveTeachers
        numberOfSuspendedTeachers
        numberOfNewTeachers
        numberOfPasswordResetTeachers
        numberOfActiveStudents
        numberOfSuspendedStudents
        numberOfNewStudents
        numberOfAlumniStudents
        numberOfSpecialStudents
        numberOfPasswordResetStudents
        totalNumberOfStudents
        totalNumberOfTeachers
      }
    }
  `,

  item: gql`
    query getSchoolQuery($id: Int) {
      getSchool(id: $id) {
        name
        id
        badgeUrl
        address
        website
        timezone
      }
    }
  `,

  settings: gql`
    query getSettingsQuery($schoolId: Int) {
      getSettings(schoolId: $schoolId) {
        pointSystem
        studentSubjectRegistration
        requestToDeleteStudent
        requestToDeleteTeacher
        minimumAttendancePercentage
        requestToDeleteParent
        requestToDeleteSubject
        requestToDeleteClass
        requestToDeleteCollege
        requestToRemoveStudentSubject
        requestToRemoveTeacherSubject
        requestToRemoveParentKids
        requestToEditPastResults
        allowAdminToEditSemester
        maxCreditUnits
        maxYears
      }
    }
  `,
  update: gql`
    mutation updateSchoolQuery(
      $id: Int
      $name: String
      $badgeUrl: Upload
      $website: String
      $timezone: String
      $address: String
    ) {
      updateSchool(
        id: $id
        name: $name
        badgeUrl: $badgeUrl
        website: $website
        timezone: $timezone
        address: $address
      ) {
        name
        id
        badgeUrl
        address
        website
        timezone
      }
    }
  `,
  updateSettings: gql`
    mutation updateSettingQuery(
      $pointSystem: Int
      $requestToDeleteStudent: Int
      $requestToDeleteTeacher: Int
      $requestToDeleteSubject: Int
      $requestToDeleteClass: Int
      $requestToDeleteCollege: Int
      $requestToDeleteParent: Int
      $requestToRemoveStudentSubject: Int
      $requestToRemoveTeacherSubject: Int
      $requestToRemoveParentKids: Int
      $requestToEditPastResults: Int
      $allowAdminToEditSemester: Int
      $maxCreditUnits: Int
      $maxYears: Int
      $minimumAttendancePercentage: Int
      $studentSubjectRegistration: Int
    ) {
      updateSettings(
        pointSystem: $pointSystem
        requestToDeleteStudent: $requestToDeleteStudent
        maxCreditUnits: $maxCreditUnits
        maxYears: $maxYears
        requestToDeleteClass: $requestToDeleteClass
        requestToDeleteCollege: $requestToDeleteCollege
        requestToDeleteSubject: $requestToDeleteSubject
        requestToDeleteTeacher: $requestToDeleteTeacher
        requestToDeleteParent: $requestToDeleteParent
        requestToRemoveStudentSubject: $requestToRemoveStudentSubject
        requestToRemoveTeacherSubject: $requestToRemoveTeacherSubject
        requestToRemoveParentKids: $requestToRemoveParentKids
        requestToEditPastResults: $requestToEditPastResults
        allowAdminToEditSemester: $allowAdminToEditSemester
        minimumAttendancePercentage: $minimumAttendancePercentage
        studentSubjectRegistration: $studentSubjectRegistration
      ) {
        pointSystem
        studentSubjectRegistration
        allowAdminToEditSemester
        requestToDeleteSubject
        requestToDeleteStudent
        maxCreditUnits
        maxYears
        minimumAttendancePercentage
        requestToDeleteParent
        requestToRemoveStudentSubject
        requestToRemoveTeacherSubject
        requestToRemoveParentKids
        requestToEditPastResults
        allowAdminToEditSemester
      }
    }
  `,
  create: gql`
    mutation createSchoolQuery(
      $name: String!
      $badgeUrl: Upload!
      $address: String!
    ) {
      createSchool(name: $name, badgeUrl: $badgeUrl, address: $address) {
        id
        name
        badgeUrl
        address
      }
    }
  `,
  wipe: gql`
    mutation deleteSchoolQuery($id: Int) {
      deleteSchool(id: $id) {
        id
      }
    }
  `,
}
