import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { getLastMonthsDateMargins } from '../functions/date';
import { invoiceQueries, paymentQueries } from '../queries';
import { validateStudentPaymentRecord } from '../views/InvoicePage/helpers/formHelper';
import useNotifications from './useNotifications';

export const useRecordPaymentMutation = ({
  amountStillOwed,
  onSuccess,
  studentId,
  invoiceId,
  ignoreValidation,
}) => {
  const [errors, setErrors] = useState({});
  const { notifyError, notifySuccess } = useNotifications()
  const [recordPayment] = useMutation(invoiceQueries.recordPayment, {
    variables: {
      studentId,
      invoiceId
    },
    refetchQueries: [
      { query: invoiceQueries.methodsInvoiceSummary },
      { query: invoiceQueries.schoolInvoiceSummary },
      { query: invoiceQueries.periodsInvoiceSummary, variables: { periods: getLastMonthsDateMargins(8) } },
      { query: invoiceQueries.paginatedPayments, variables: { pagination: { first: 10 } } },
      { query: paymentQueries.getStudentUnpaidInvoices, variables: { studentId } },
    ]
  });

  const onAccept = async ({ amountPaid, identificationCode, paymentMethod, transactionFee, serviceFee }) => {
    const formErrors = validateStudentPaymentRecord({ amount: amountPaid }, amountStillOwed);
    if (formErrors.amount && !ignoreValidation) {
      setErrors(formErrors);
      return;
    }
    try {
      await recordPayment({
        variables: {
          studentId,
          amount: parseFloat(amountPaid),
          identificationCode,
          method: paymentMethod,
          transactionFee,
          serviceFee
        }
      })
      onSuccess()
      notifySuccess()
    } catch (e) {
      notifyError(e)
    }
  }

  return {
    errors,
    onAccept,
    setErrors
  }
}