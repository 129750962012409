import PropTypes from 'prop-types'
import React from 'react'

function JitsuTable({ headers, children, className }) {
  return (
    <div className=" ring-opacity-5 md:rounded-lg">
      <table
        className={`min-w-full divide-y divide-gray-300 ${className} rounded-lg`}
      >
        <thead>
          {headers.length > 0 && (
            <tr className="divide-x divide-gray-200 rounded-lg text-black border-none">
              {headers.map((header, index) => (
                <th
                  className={`px-2 py-2 text-left text-xs font-semibold border-none 
                    ${index === 0 ? 'rounded-tl-lg' : ''} ${
                    index === headers.length - 1 ? 'rounded-tr-lg' : ''
                  }`}
                  key={header.title || header}
                >
                  {header.title || header}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
      </table>
    </div>
  )
}

JitsuTable.defaultProps = {
  headers: [],
  children: (
    <tr>
      <td />
    </tr>
  ),
  className: '',
}

JitsuTable.propTypes = {
  headers: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}

export default JitsuTable
