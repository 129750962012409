import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewTeacher = lazy(() => import('../views/TeacherPage/ViewTeacher'));
const CreateTeacher = lazy(() => import('../views/TeacherPage/CreateTeacher'));
const EditTeacher = lazy(() => import('../views/TeacherPage/EditTeacher'));
const DeleteTeacher = lazy(() => import('../views/TeacherPage/DeleteTeacher'));
const TeacherPage = lazy(() => import('../views/TeacherPage/Teachers'));

function TeacherRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateTeacher/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><TeacherPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewTeacher/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteTeacher/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditTeacher/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default TeacherRoutes;
