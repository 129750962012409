import React from 'react';
import JitsuAlert from './JitsuAlert';

function JitsuSentryFallbackComponent() {
  return <JitsuAlert headerText="Error" type="warning">
  Sorry an error occured. Please refresh the page
</JitsuAlert>
}

export default JitsuSentryFallbackComponent;
