import gql from 'graphql-tag'

const teacherStudentRemarkFrag = `
  createdAt,
  updatedAt,
  topic,
  description,
  fileUrl,
  teacher { firstName, lastName, id, regNumber }
  student { firstName, lastName, id, regNumber }
`

export default {
  list: gql`query {
    getTeacherStudentRemarks{
      id,
      ${teacherStudentRemarkFrag}
    }
  }`,
  item: gql`
    query getTeacherStudentRemarkQuery($id: Int) {
      getTeacherStudentRemark(id: $id) {
        id,
        ${teacherStudentRemarkFrag}
      }
    }
  `,
  create: gql`
    mutation createTeacherStudentRemarkQuery(
      $description: String!
      $topic: String!
      $fileUrl: Upload
      $studentId: Int!
    ) {
      createTeacherStudentRemark(
        description: $description
        topic: $topic
        fileUrl: $fileUrl
        studentId: $studentId
      ) {
        id,
        ${teacherStudentRemarkFrag}
      }
    }
  `,
  update: gql`
    mutation updateTeacherStudentRemarkQuery(
      $id: Int
      $description: String!
      $topic: String!
      $fileUrl: Upload
      $studentId: Int!
    ) {
      updateTeacherStudentRemark(
        id: $id,
        description: $description
        topic: $topic
        fileUrl: $fileUrl
        studentId: $studentId
      ) {
        id,
        ${teacherStudentRemarkFrag}
      }
    }
  `,

  wipe: gql`
    mutation deleteTeacherStudentRemarkQuery($id: Int) {
      deleteTeacherStudentRemark(id: $id) {
        id
      }
    }
  `,
  paginatedTeacherStudentRemarks: gql`
    query getPaginatedTeacherStudentRemarksQuery(
      $pagination: PaginationInput
      $studentId: Int
      $after: String
    ) {
      getPaginatedTeacherStudentRemarks(
        pagination: $pagination
        after: $after
        studentId: $studentId
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            topic
            createdAt
            updatedAt
            teacher {
              id
              firstName
              lastName
              regNumber
            }
          }
        }
      }
    }
  `,
}
