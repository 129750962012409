import PropTypes from 'prop-types'
import React from 'react'

function JitsuTableCell({ children, className, colSpan }) {
  return (
    <td
      colSpan={colSpan}
      className={`${className} whitespace-nowrap py-2 px-2 text-xs text-gray-500 border-none`}
    >
      {children}
    </td>
  )
}

JitsuTableCell.defaultProps = {
  children: '',
  className: '',
}

JitsuTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  colSpan: PropTypes.any,
  className: PropTypes.string.isRequired,
}

export default JitsuTableCell
