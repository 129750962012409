import PropTypes from 'prop-types'
import React from 'react'
import useAppTheme from '../hooks/useAppTheme'


const commonClassName = `inline-flex items-center px-3 py-2 border border-transparent
shadow-sm text-xs leading-4 font-medium rounded-md text-white
 focus:outline-none focus:ring-1 focus:ring-offset-2`

export const BUTTON_TYPES = {
  DANGER: 'DANGER',
  PRIMARY: 'PRIMARY',
  SUCCESS: 'SUCCESS',
  DISABLED: 'DISABLED',
  NORMAL: 'NORMAL',
  LONG: 'LONG',
}

function JitsuButton({
  loading,
  onClick,
  name,
  value,
  disabled,
  colour,
  id,
  type,
  ButtonIcon,
}) {
  const theme = useAppTheme()
  const primaryClassName = `${commonClassName} ${theme.bgPrimary600} ${theme.hoverBgPrimary700} ${theme.focusRingPrimary500}`
  const normalClassName = `${commonClassName} ${theme.bgPrimary600} ${theme.hoverBgPrimary700} ${theme.focusRingPrimary500}`
  const dangerClassName = `${commonClassName} bg-red-600 hover:bg-red-700 focus:ring-red-500`
  const successClassName = `${commonClassName} bg-green-600 hover:bg-green-700 focus:ring-green-500`
  const disabledClassName = `${commonClassName} bg-neutral-300`
  const longClassName = `${commonClassName} flex justify-center ${theme.bgPrimary600} ${theme.hoverBgPrimary700} ${theme.focusRingPrimary500} w-full items-center py-3`

  const buttonTypeToClassName = {
    [BUTTON_TYPES.DANGER]: dangerClassName,
    [BUTTON_TYPES.PRIMARY]: primaryClassName,
    [BUTTON_TYPES.SUCCESS]: successClassName,
    [BUTTON_TYPES.DISABLED]: disabledClassName,
    [BUTTON_TYPES.NORMAL]: normalClassName,
    [BUTTON_TYPES.LONG]: longClassName,
  }

  const btnClassNames =
    colour === 'bg-red-900'
      ? buttonTypeToClassName[BUTTON_TYPES.DANGER]
      : buttonTypeToClassName[type] ||
        buttonTypeToClassName[BUTTON_TYPES.PRIMARY]

  const classNames = disabled
    ? buttonTypeToClassName[BUTTON_TYPES.DISABLED]
    : btnClassNames

  return (
    <button
      type="button"
      onClick={disabled || loading ? () => {} : onClick}
      name={name}
      id={id}
      disabled={disabled}
      className={classNames}
    >
      {ButtonIcon && (
        <ButtonIcon
          className={`-ml-0.5 ${value ? 'mr-2' : ''} h-4 w-4`}
          aria-hidden="true"
        />
      )}
      {!!value && `${value} `}
      {loading ? '...' : ''}
    </button>
  )
}

JitsuButton.defaultProps = {
  style: {},
  loading: false,
  disabled: false,
  name: '',
  id: '',
  value: '',
  type: BUTTON_TYPES.PRIMARY,
}

JitsuButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  colour: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  ButtonIcon: PropTypes.any,
  type: PropTypes.string,
}

export default JitsuButton
