import React from 'react'
import PropTypes from 'prop-types'
import { NAIRA_SIGN } from '../../../constants/common'
import Modal from '../../../components/Modal'
import JitsuInput from '../../../components-elements/JitsuInput'
import JitsuSelect from '../../../components-elements/JitsuSelect'
import { PAYMENT_METHOD_OPTIONS } from '../../../constants/payment'
import SearchAndSelect from '../../../components/SearchAndSelect'
import { isNumber } from '../../../functions'

function RecordPaymentModal({
  modalTitle,
  closeModal,
  onAccept,
  modalOnScreen,
  amountPaid,
  paymentMethod,
  amountError,
  onChangeAmountPaid,
  recordId,
  onChangeMethod,
  receiptId,
  showStudentAndInvoiceSelection,
  student,
  invoiceId,
  onChangeReceiptId,
  amountStillOwed,
  onChangeStudent,
  onChangeInvoice,
  invoiceOptions,
  disabledConfirmButton,
}) {
  return (
    <Modal
      onClose={closeModal}
      title={modalTitle}
      onAccept={onAccept}
      initiallyOnScreen={modalOnScreen}
      acceptButtonLabel="Save"
      acceptButtonDisabled={disabledConfirmButton}
    >
      <>
        {showStudentAndInvoiceSelection && (
          <div className="mb-2">
            <div>
              <SearchAndSelect
                isSingleSelect
                value={student}
                through="student"
                valueKey="actualId"
                textKey="text"
                onChange={onChangeStudent}
              />
            </div>
            <div>
              <JitsuSelect
                label="Invoice"
                value={invoiceId}
                onChange={onChangeInvoice}
                name="invoiceId"
                options={invoiceOptions}
                disabled={!student[0]}
              />
            </div>
          </div>
        )}

        <div className="flex w-full">
          <div className="w-32">
            <JitsuInput
              value={amountPaid}
              name={`amoundPaid-${recordId}`}
              onChange={onChangeAmountPaid}
              label="Amount"
              error={amountError}
              className="pl-4"
              disabled={!invoiceId}
            />
          </div>
          <div className="ml-4 w-72">
            <JitsuSelect
              label="Payment method"
              value={paymentMethod}
              onChange={onChangeMethod}
              name="semesterId"
              options={PAYMENT_METHOD_OPTIONS}
              disabled={!invoiceId}
            />
          </div>
        </div>
        <JitsuInput
          value={receiptId}
          name={`receipt-${recordId}`}
          onChange={onChangeReceiptId}
          label="Receipt ID"
          className="pl-4"
          disabled={!invoiceId}
        />
        {isNumber(amountStillOwed) && (
          <div className="mt-2 text-xs text-gray-700">
            Owing {NAIRA_SIGN}
            {amountStillOwed}
          </div>
        )}
      </>
    </Modal>
  )
}

RecordPaymentModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  modalOnScreen: PropTypes.bool.isRequired,
  amountPaid: PropTypes.any.isRequired,
  paymentMethod: PropTypes.string,
  amountError: PropTypes.string,
  onChangeAmountPaid: PropTypes.func.isRequired,
  recordId: PropTypes.string,
  onChangeMethod: PropTypes.func.isRequired,
  receiptId: PropTypes.string.isRequired,
  onChangeReceiptId: PropTypes.func.isRequired,
  amountStillOwed: PropTypes.number.isRequired,
  student: PropTypes.array,
  invoiceId: PropTypes.number,
  onChangeStudent: PropTypes.func.isRequired,
  onChangeInvoice: PropTypes.func.isRequired,
  showStudentAndInvoiceSelection: PropTypes.bool,
  invoiceOptions: PropTypes.array.isRequired,
  disabledConfirmButton: PropTypes.bool,
}

export default RecordPaymentModal
