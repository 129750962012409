import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewClass = lazy(() => import('../views/ClassPage/ViewClass'));
const CreateClass = lazy(() => import('../views/ClassPage/CreateClass'));
const EditClass = lazy(() => import('../views/ClassPage/EditClass'));
const DeleteClass = lazy(() => import('../views/ClassPage/DeleteClass'));
const ClassPage = lazy(() => import('../views/ClassPage/Classes'));

function ClassRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateClass/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><ClassPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewClass/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteClass/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditClass/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default ClassRoutes;
