import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { deleteToken, getCurrentUser } from '../../functions/user'

export const UserContext = React.createContext()

function UserContextProvider({ children }) {
  const [user] = useState(getCurrentUser())
  const value = useMemo(() => [user], [user])
  if (!user.userId || user.userStatus === 'SUSPENDED') {
    localStorage.setItem('relocateUrl', window.location.href)
    deleteToken()
    return <></>
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

UserContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default UserContextProvider
