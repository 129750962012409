import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import JitsuTooltip from './JitsuTooltip';

function JitsuLabel({ forValue, children, required, tooltip }) {
  return (
    <>
      <label
        className="block text-gray-600 text-xs font-bold mb-2 flex"
        htmlFor={forValue}
      >
        {children}
        {required && <span className="text-red-500 ml-1">*</span>}
        {tooltip && (
          <span className="text-gray-500 ml-2">
            <JitsuTooltip tooltipText={tooltip} position="right" hideTooltip />
          </span>
        )}
        
      </label>
    </>
  )
}

JitsuLabel.propTypes = {
  forValue: PropTypes.string,
  children: PropTypes.any,
  // optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
}

export default JitsuLabel
