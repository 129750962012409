import { isNumber } from '../../../functions'
import { minValue } from '../../../helpers/commonValidations'
import { INVOICE_FOR } from './fields'

const validate = ({ name, code }) => ({
  name: name.length < 3 ? minValue(3) : null,
  code: code.length < 2 ? minValue(2) : null,
})

const createInitialFormValues = ({ data, isUpdating }) => {
  if (!isUpdating) {
    return {
      name: '',
      code: '',
      description: '',
      amount: '',
      classes: [],
      students: [],
      invoiceFor: 'CLASSES',
    }
  }
  const { getInvoice } = data
  return {
    ...getInvoice,
    paymentSubAccount: getInvoice.paymentSubAccount
      ? getInvoice.paymentSubAccount.id
      : '',
  }
}

const createRequestData = ({ form }) => ({
  ...form,
  paymentSubAccountId: form.paymentSubAccount,
  amount: parseFloat(form.amount, 10),
  studentsIds:
    form.invoiceFor === INVOICE_FOR.STUDENTS
      ? form.students.map(({ actualId }) => parseInt(actualId, 10))
      : [],
  classesIds:
    form.invoiceFor === INVOICE_FOR.CLASSES
      ? form.classes.map(({ actualId, id }) => parseInt(actualId || id, 10))
      : [],
})

const validateAmount = (amount, amountStillOwed) => {
  if (!isNumber(amount)) return 'Invalid amount'
  if (parseFloat(amount) > amountStillOwed) return `Limit: ${amountStillOwed}`
  return null
}

const validateStudentPaymentRecord = (
  { amount, paymentSubAccount: paymentSubAccountId },
  amountStillOwed,
) => ({
  amount: validateAmount(amount, amountStillOwed),
  paymentSubAccountId: !paymentSubAccountId ? 'this is required' : null,
})

export {
  validate,
  validateStudentPaymentRecord,
  createInitialFormValues,
  createRequestData,
}
