import gql from 'graphql-tag'

const invoiceFrag = `
  invoiceId,
  invoiceType,
  amount,
  discount,
  createdAt,
  updatedAt,
  schoolId,
  identificationCode,
  classId,
  studentId,
  status,
  description,
`

const paymentQueries = {
  getPaymentMethods: gql`
    query getPaymentMethodsQuery {
      getPaymentMethods {
        name
        label
        active
        configured
        id
        publicKey
      }
    }
  `,
  getClassInvoices: gql`
    query getClassInvoicesQuery {
      getClassInvoices {
        id
      }
    }
  `,
  getStudentInvoices: gql`query getStudentInvoicesQuery($regNumber: String) {
    getStudentInvoices(regNumber: $regNumber) {
      ${invoiceFrag}
    }
  }`,
  connectToEduPaypoint: gql`
    mutation connectToEduPaypointQuery(
      $username: String
      $publicKey: String
      $name: String
    ) {
      connectToEduPaypoint(
        username: $username
        publicKey: $publicKey
        name: $name
      ) {
        name
        label
        active
        configured
      }
    }
  `,

  activatePaymentMethod: gql`
    mutation activatePaymentMethodQuery($id: Int, $name: String) {
      activatePaymentMethod(id: $id, name: $name) {
        name
        label
        active
        configured
      }
    }
  `,

  deactivatePaymentMethod: gql`
    mutation deactivatePaymentMethodQuery($id: Int, $name: String) {
      deactivatePaymentMethod(id: $id, name: $name) {
        name
        label
        active
        configured
      }
    }
  `,

  getStudentUnpaidInvoices: gql`
    query getStudentUnpaidInvoicesQuery(
      $studentId: Int
      $invoiceStatuses: [String]
    ) {
      getStudentUnpaidInvoices(
        studentId: $studentId
        invoiceStatuses: $invoiceStatuses
      ) {
        id
        status
        name
        code
        amountPaid
        amount
      }
    }
  `,

  saveFlutterwaveCredentials: gql`
    mutation saveFlutterwaveCredentialsQuery(
      $publicKey: String
      $secretKey: String
      $encryptionKey: String
    ) {
      saveFlutterwaveCredentials(
        publicKey: $publicKey
        secretKey: $secretKey
        encryptionKey: $encryptionKey
      ) {
        name
        label
        active
        configured
      }
    }
  `,
}

export default paymentQueries
