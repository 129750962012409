import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import useAppTheme from '../../../hooks/useAppTheme'
import { paymentQueries } from '../../../queries'
import RecordPaymentModal from './RecordPaymentModal'
import JitsuButton from '../../../components-elements/JitsuButton'
import { NAIRA_SIGN } from '../../../constants/common'
import { useRecordPaymentMutation } from '../../../hooks/useRecordPaymentMutation'

function RecordPayment({
  createModalTitle,
  initialInvoiceId,
  initialStudentId,
  initialAmoundAlreadyPaid,
  initialInvoiceAmount,
  recordId,
  isButton,
}) {
  const [invoiceId, setInvoiceId] = useState()
  const [student, setStudent] = useState([])
  const [studentId, setStudentId] = useState()
  const [amountStillOwed, setAmountStillOwed] = useState()
  const [modalOnScreen, setModalOnScreen] = useState(false)
  const [amountPaid, setAmountPaid] = useState('')
  const [paymentMethod, setPaymentMethod] = useState()
  const [receiptId, setReceiptId] = useState('')
  const { borderPrimary100 } = useAppTheme()

  useEffect(() => {
    if (initialAmoundAlreadyPaid && initialInvoiceAmount) {
      setAmountStillOwed(initialInvoiceAmount - initialAmoundAlreadyPaid)
    }
  }, [initialAmoundAlreadyPaid, initialInvoiceAmount])

  const { data, loading, error } = useQuery(
    paymentQueries.getStudentUnpaidInvoices,
    {
      variables: {
        studentId,
      },
      skip: !!initialInvoiceId || !studentId,
    },
  )

  const invoiceOptions =
    loading || error || !data || !data.getStudentUnpaidInvoices
      ? []
      : data.getStudentUnpaidInvoices.map((unpaidInvoice) => ({
          ...unpaidInvoice,
          value: unpaidInvoice.id,
          text: `${unpaidInvoice.name} (${unpaidInvoice.code})`,
          subLabel: `- Owing ${NAIRA_SIGN}${
            unpaidInvoice.amount - unpaidInvoice.amountPaid
          }`,
        }))

  const resetForm = (ignoreStudent) => {
    if (!ignoreStudent) {
      setStudent([])
      setStudentId()
    }
    setAmountPaid('')
    setReceiptId('')
    setPaymentMethod('')
    setInvoiceId()
    setAmountStillOwed()
  }

  const closeModal = () => {
    resetForm()
    setModalOnScreen(false)
  }
  const openModal = () => {
    setModalOnScreen(true)
  }

  const { onAccept, errors, setErrors } = useRecordPaymentMutation({
    amountStillOwed,
    onSuccess: closeModal,
    studentId: studentId || initialStudentId,
    invoiceId: invoiceId || initialInvoiceId,
  })

  const onChangeAmountPaid = (value) => {
    setAmountPaid(value)
    setErrors({ ...errors, amount: undefined })
  }
  const onChangeReceiptId = (value) => {
    setReceiptId(value)
  }
  const onChangeMethod = (value) => {
    setPaymentMethod(value)
  }
  const onChangeInvoiceId = (value) => {
    setInvoiceId(value)
    if (value) {
      const unpaidInvoice = data.getStudentUnpaidInvoices.find(
        ({ id }) => value === id,
      )
      setAmountStillOwed(unpaidInvoice.amount - unpaidInvoice.amountPaid)
    }
  }
  const onChangeStudent = (value) => {
    setStudent(value)
    setStudentId(value.length > 0 ? value[0].actualId : undefined)
    resetForm(true)
  }

  const modalTitle = createModalTitle ? createModalTitle({}) : 'Record payment'
  const disabledButton =
    (!studentId && !initialStudentId) ||
    (!invoiceId && !initialInvoiceId) ||
    !!errors.amount ||
    !paymentMethod ||
    !amountPaid

  return (
    <>
      <div
        className={`flex justify-end text-blue-700 border-t ${borderPrimary100} py-4`}
      >
        {isButton ? (
          <JitsuButton onClick={openModal} value="Record payment" />
        ) : (
          <span
            className="underline cursor-pointer"
            role="button"
            onClick={openModal}
          >
            Record payment
          </span>
        )}
      </div>
      {modalOnScreen && (
        <RecordPaymentModal
          modalTitle={modalTitle}
          modalOnScreen={modalOnScreen}
          acceptButtonLabel="Save"
          amountPaid={amountPaid}
          paymentMethod={paymentMethod}
          recordId={recordId}
          invoiceId={invoiceId || initialInvoiceId}
          receiptId={receiptId}
          invoiceOptions={invoiceOptions || []}
          student={student}
          amountError={errors.amount}
          amountStillOwed={amountStillOwed}
          onChangeInvoice={onChangeInvoiceId}
          onChangeStudent={onChangeStudent}
          onChangeReceiptId={onChangeReceiptId}
          onChangeMethod={onChangeMethod}
          onChangeAmountPaid={onChangeAmountPaid}
          onAccept={() =>
            onAccept({
              amountPaid,
              identificationCode: receiptId,
              paymentMethod,
            })
          }
          closeModal={closeModal}
          showStudentAndInvoiceSelection={!initialStudentId}
          disabledConfirmButton={disabledButton}
        />
      )}
    </>
  )
}

RecordPayment.propTypes = {
  initialInvoiceId: PropTypes.number,
  recordId: PropTypes.string,
  initialStudentId: PropTypes.number,
  initialInvoiceAmount: PropTypes.number,
  initialAmoundAlreadyPaid: PropTypes.number,
  createModalTitle: PropTypes.func,
  isButton: PropTypes.bool,
}

export default RecordPayment
