import React, { useState } from 'react'
import PropTypes from 'prop-types'
import JitsuInput from '../../../components-elements/JitsuInput'
import { textChange } from '../../../functions/textChange'
import SearchAndSelect from '../../../components/SearchAndSelect'
import JitsuSelect from '../../../components-elements/JitsuSelect'
import JitsuDateInput from '../../../components-elements/JitsuDateInput'
import { PAYMENT_METHOD_OPTIONS } from '../../../constants/payment'
import SwitchToggle from '../../../components/SwitchToggle'
import JitsuButton from '../../../components-elements/JitsuButton'
import useUser from '../../../hooks/useUser'
import { formatDate } from '../../../functions/date'
import JitsuTabBody from '../../../components-elements/JitsuTabBody'
import JitsuTab from '../../../components-elements/JitsuTab'
import { DATETIME } from '../../../constants/dateInputTypes'
import { MagnifyingGlassIconOutline } from '../../../assets/icons'

const searchAndSelectProps = {
  valueKey: 'actualId',
  textKey: 'text',
  isSingleSelect: true,
}

const tabValues = {
  exact: 'exact',
  range: 'range',
}

const dateTabHeaders = [
  { text: 'Exact day', value: tabValues.exact },
  { text: 'Date range', value: tabValues.range },
]

const dateTabBodyProps = {
  overflow: '',
  shadow: '',
  bg: '',
  height: '',
}

function PaymentsFilter({ onChange }) {
  const user = useUser()
  const [usedFilter, setUsedFilter] = useState({})
  const [identificationCode, setIdentificationCode] = useState('')
  const [method, setMethod] = useState('')
  const [createdAt, setCreatedAt] = useState(formatDate(new Date()))
  const [start, setStart] = useState(user.semesterStartDate)
  const [end, setEnd] = useState(user.semesterEndDate)
  const [teacherId, setTeacherId] = useState([])
  const [studentId, setStudentId] = useState([])
  const [invoiceId, setInvoiceId] = useState([])
  const [useDate, setUseDate] = useState(0)
  const [selectedTab, setSelectedTab] = useState('exact')
  const selectTab = (tab) => {
    setSelectedTab(tab)
  }
  const createFilter = () => ({
    method,
    identificationCode,
    createdAt: useDate && selectedTab === tabValues.exact ? createdAt : '',
    start: useDate && selectedTab === tabValues.range ? start : '',
    end: useDate && selectedTab === tabValues.range ? end : '',
    teacherId: teacherId[0]?.actualId,
    studentId: studentId[0]?.actualId,
    invoiceId: invoiceId[0]?.actualId,
  })

  const handleFilterChange = () => {
    const filterData = createFilter()
    onChange(filterData)
    setUsedFilter(filterData)
  }

  const handleTransactionIdChange = (value) => {
    setIdentificationCode(value)
  }

  const handleMethodChange = (value) => {
    setMethod(value)
  }

  const handleTeacherIdChange = (value) => {
    setTeacherId(value)
  }

  const handleStudentIdChange = (value) => {
    setStudentId(value)
  }

  const handleInvoiceIdChange = (value) => {
    setInvoiceId(value)
  }

  const handleStartChange = (value) => {
    setStart(value)
  }

  const handleEndChange = (value) => {
    setEnd(value)
  }

  const handleCreatedAtChange = (value) => {
    setCreatedAt(value)
  }

  const handleUseDateChange = (value) => {
    setUseDate(value)
  }
  const unchanged =
    JSON.stringify(usedFilter) === JSON.stringify(createFilter())

  return (
    <div className="mb-2 py-2 z-10">
      <div className="flex space-x-4">
        <div className="grid grid-cols-2 gap-x-4">
          <div>
            <JitsuInput
              label="Receipt ID"
              value={identificationCode}
              onChange={handleTransactionIdChange}
              placeholder="Receipt ID"
              name="identificationCode"
            />
          </div>
          <div>
            <JitsuSelect
              label="Payment method"
              value={method}
              onChange={handleMethodChange}
              name="semesterId"
              options={[{ text: 'All', value: '' }, ...PAYMENT_METHOD_OPTIONS]}
              ignoreFirstOption
            />
          </div>
          <div>
            <SearchAndSelect
              displayTextFcn={(selection) =>
                selection.text || `${selection.firstName} ${selection.lastName}`
              }
              through="teacher"
              value={teacherId}
              onChange={handleTeacherIdChange}
              label="Recorded by"
              {...searchAndSelectProps}
            />
          </div>
          <div>
            <SearchAndSelect
              displayTextFcn={(selection) =>
                selection.text || `${selection.firstName} ${selection.lastName}`
              }
              through="student"
              value={studentId}
              onChange={handleStudentIdChange}
              label={`${textChange('Student')}`}
              {...searchAndSelectProps}
            />
          </div>
          <div>
            <SearchAndSelect
              displayTextFcn={(selection) =>
                selection.text || `${selection.name}`
              }
              through="invoice"
              value={invoiceId}
              onChange={handleInvoiceIdChange}
              label={`${textChange('Invoice')}`}
              {...searchAndSelectProps}
            />
          </div>
        </div>
        <div className="">
          <div className="mb-2">
            <SwitchToggle
              value={useDate}
              onChange={handleUseDateChange}
              label="Use date"
            />
          </div>
          {!!useDate && (
            <JitsuTab
              headers={dateTabHeaders}
              onClickTabHeader={selectTab}
              selected={selectedTab}
            >
              <JitsuTabBody
                onScreen={selectedTab === tabValues.exact}
                key="exact"
                {...dateTabBodyProps}
              >
                <div className="px-3 w-36">
                  <JitsuDateInput
                    disabled={!useDate}
                    value={createdAt}
                    onChange={handleCreatedAtChange}
                    label="Transaction Date"
                  />
                </div>
              </JitsuTabBody>
              <JitsuTabBody
                onScreen={selectedTab === tabValues.range}
                key="range"
                {...dateTabBodyProps}
              >
                <div className="px-3">
                  <JitsuDateInput
                    disabled={!useDate}
                    value={start}
                    onChange={handleStartChange}
                    label="Start"
                    dateInputType={DATETIME}
                  />
                  <JitsuDateInput
                    disabled={!useDate}
                    value={end}
                    onChange={handleEndChange}
                    dateInputType={DATETIME}
                    label="End"
                  />
                </div>
              </JitsuTabBody>
            </JitsuTab>
          )}
        </div>
      </div>
      <JitsuButton
        onClick={handleFilterChange}
        value="Search"
        ButtonIcon={MagnifyingGlassIconOutline}
        disabled={unchanged}
      />
    </div>
  )
}

PaymentsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default PaymentsFilter
