import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';
import styled from 'styled-components';
import JitsuSentryFallbackComponent from './JitsuSentryFallbackComponent';
import { DATE, DATETIME, TIME } from '../constants/dateInputTypes';
import JitsuField from './JitsuField';
import JitsuLabel from './JitsuLabel';

const DateDiv = styled.div`
  .react-date-picker__wrapper, .react-datetime-picker__wrapper, .react-time-picker__wrapper{
    border: none;
  }
  .react-date-picker--disabled, .react-datetime-picker--disabled, .react-time-picker--disabled {
    background-color: transparent
  }
  .react-date-picker__inputGroup__input, .react-datetime-picker__inputGroup__input, .react-time-picker__inputGroup__input {
    height: 20px;
    font-size: 12px;
    background-color: transparent;
    padding: 0.4rem;
  }
  .react-date-picker__inputGroup__divider, 
  .react-datetime-picker__inputGroup__divider, .react-time-picker__inputGroup__divider{
    margin: 4px
  }
`;

const ActualDatePicker = ({ dateInputType, value, onChange, name, className, disabled }) => { // eslint-disable-line
  const dateOnChange = val => onChange(val, name);
  const timeOnChange = val => onChange(val, name);
  switch (dateInputType) {
  case TIME:
    return <Sentry.ErrorBoundary fallback={ JitsuSentryFallbackComponent } showDialog>
      <TimePicker
        value={ value }
        onChange={ timeOnChange }
        showTimeSelect
        showTimeSelectOnly
        disabled={ disabled }
        timeIntervals={ 5 }
        format="hh:mm a"
        timeCaption="Time"
        name={ name }
        disableClock
        className={ className }
        clearIcon={ null }
        calendarIcon={ null }
      />
    </Sentry.ErrorBoundary>;
  case DATETIME:
    return <Sentry.ErrorBoundary fallback={ JitsuSentryFallbackComponent } showDialog>
      <DateTimePicker
        value={ new Date(value) }
        onChange={ dateOnChange }
        showTimeSelect
        timeFormat="hh:mm a"
        timeIntervals={ 5 }
        disableClock
        format="y-MM-dd hh:mm a"
        timeCaption="time"
        name={ name }
        disabled={ disabled }
        className={ className }
        clearIcon={ null }
        calendarIcon={ null }
      />
    </Sentry.ErrorBoundary>;
  default:
    return <Sentry.ErrorBoundary fallback={ JitsuSentryFallbackComponent } showDialog>
      <DatePicker
        value={ new Date(value) }
        onChange={ dateOnChange }
        format="y-MM-dd"
        name={ name }
        disableClock
        className={ className }
        disabled={ disabled }
        clearIcon={ null }
        calendarIcon={ null }
      />
    </Sentry.ErrorBoundary>;
  }
};

function DateInput({
  value,
  onChange,
  label,
  dateInputType,
  name,
  error,
  id,
  className,
  wrapperClassName,
  optional,
  required,
  tooltip,
  disabled
}) {
  return <JitsuField id={ `datePicker-${id || name}` } error={ error } className={ wrapperClassName }>
    <JitsuLabel id={ `${id || name}Label` } optional={ optional } required={ required } tooltip={ tooltip }>
      { label }
    </JitsuLabel>
    <DateDiv
      className="appearance-none border rounded text-xs text-gray-700 leading-tight focus:outline-none mt-2"
    >
      <ActualDatePicker
        value={ value }
        onChange={ onChange }
        dateInputType={ dateInputType }
        name={ name }
        className={ `${className} adlam-date text-xs font-light` }
        disabled={ disabled }
      />
    </DateDiv>
  </JitsuField>;
}

DateInput.defaultProps = {
  dateInputType: DATE,
  id: '',
  className: '',
  wrapperClassName: ''
};

DateInput.propTypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
  dateInputType: PropTypes.string.isRequired
};

export default DateInput;
