import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewNote = lazy(() => import('../views/NotePage/ViewNote'));
const CreateNote = lazy(() => import('../views/NotePage/CreateNote'));
const EditNote = lazy(() => import('../views/NotePage/EditNote'));
const DeleteNote = lazy(() => import('../views/NotePage/DeleteNote'));
const NotePage = lazy(() => import('../views/NotePage/Notes'));

function NoteRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateNote/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><NotePage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewNote/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteNote/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditNote/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default NoteRoutes;
