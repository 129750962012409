import gql from 'graphql-tag';

export default {
  list: gql`query {
    getSemesters{
      year,
      id,
      startDate,
      endDate,
      scoresSubmissionDeadline,
      scoresSubmissionDeadline,
      subjectRegistrationDeadline
    }
  }`,
  item: gql`query getSemesterQuery($id: Int) {
    getSemester(id: $id){
      year,
      id,
      startDate,
      endDate,
      scoresSubmissionDeadline,
      name,
      subjectRegistrationDeadline
    }
  }`,
  currentSemester: gql`query {
    getCurrentSemester{
      year,
      id,
      startDate,
      endDate,
      scoresSubmissionDeadline,
      name,
      subjectRegistrationDeadline
    }
  }`,
  studentSemesterOption: gql`query getStudentSemesterOptionsQuery($studentId: Int) {
    getStudentSemesterOptions(studentId: $studentId){
      year,
      id,
      startDate,
      endDate,
      scoresSubmissionDeadline,
      subjectRegistrationDeadline,
      name
    }
  }`,
  teacherSemesterOption: gql`query getTeacherSemesterOptionsQuery($teacherId: Int) {
    getTeacherSemesterOptions(teacherId: $teacherId){
      name,
      year,
      id,
      startDate,
      endDate,
      scoresSubmissionDeadline,
      subjectRegistrationDeadline
    }
  }`,
  create: gql`mutation createSemesterQuery(
    $name: String!,
    $scoresSubmissionDeadline: String!,
    $subjectRegistrationDeadline: String!,
    $setAsCurrentSemester: Int,
    $newYear: Int,
    $startDate: String!,
    $year: String!,
    $endDate: String!) {
      createSemester(
          name: $name,
          setAsCurrentSemester: $setAsCurrentSemester,
          newYear: $newYear,
          year: $year,
          subjectRegistrationDeadline: $subjectRegistrationDeadline
          scoresSubmissionDeadline: $scoresSubmissionDeadline,
          startDate: $startDate,
          endDate: $endDate){
              id,
              startDate,
              endDate,
              setAsCurrentSemester,
              scoresSubmissionDeadline,
              subjectRegistrationDeadline,
              name,
              year
      }
  }`,
  update: gql`mutation updateSemesterQuery(
    $id: Int,
    $name: String!,
    $scoresSubmissionDeadline: String!,
    $subjectRegistrationDeadline: String!,
    $startDate: String!,
    $year: String!,
    $endDate: String!) {
      updateSemester(
          id: $id,
          name: $name,
          year: $year,
          subjectRegistrationDeadline: $subjectRegistrationDeadline
          scoresSubmissionDeadline: $scoresSubmissionDeadline,
          startDate: $startDate,
          endDate: $endDate){
              id,
              startDate,
              endDate,
              scoresSubmissionDeadline,
              subjectRegistrationDeadline,
              name,
              year
      }
  }`
};
