import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewStudent = lazy(() => import('../views/StudentPage/ViewStudent'));
const CreateStudent = lazy(() => import('../views/StudentPage/CreateStudent'));
const EditStudent = lazy(() => import('../views/StudentPage/EditStudent'));
const DeleteStudent = lazy(() => import('../views/StudentPage/DeleteStudent'));
const StudentPage = lazy(() => import('../views/StudentPage/Students'));

function StudentRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateStudent/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><StudentPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewStudent/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteStudent/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditStudent/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default StudentRoutes;
