import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewPeriod = lazy(() => import('../views/PeriodPage/ViewPeriod'));
const CreatePeriod = lazy(() => import('../views/PeriodPage/CreatePeriod'));
const EditPeriod = lazy(() => import('../views/PeriodPage/EditPeriod'));
const DeletePeriod = lazy(() => import('../views/PeriodPage/DeletePeriod'));
const PeriodPage = lazy(() => import('../views/PeriodPage/Timetable'));

function PeriodRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreatePeriod/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><PeriodPage/></SuspenseComponent> }/>
    <Route path=":id" element={ <SuspenseComponent><ViewPeriod/></SuspenseComponent> } >
      <Route path="delete" element={ <SuspenseComponent><DeletePeriod/></SuspenseComponent> } />
    </Route>
    <Route path=":id/update" element={ <SuspenseComponent><EditPeriod/></SuspenseComponent> } />
  </Routes>;
}

export default PeriodRoutes;
