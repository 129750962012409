import gql from 'graphql-tag'

export default {
  dNews: gql`
    query getPersonalNewsQuery($userId: Int, $userRole: String) {
      getPersonalNews(userId: $userId, userRole: $userRole) {
        id
        topic
        description
        teacher {
          id
          firstName
          lastName
        }
        fileUrl
        createdAt
        excerpt
      }
    }
  `,

  list: gql`
    query getAllNewsQuery(
      $startDate: String
      $endDate: String
      $pagination: PaginationInput
      $after: String
    ) {
      getAllNews(
        startDate: $startDate
        endDate: $endDate
        pagination: $pagination
        after: $after
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            description
            teacher {
              id
              firstName
              lastName
            }
            subjects {
              id
              code
              name
            }
            fileUrl
            createdAt
            topic
            type
            roles
          }
        }
      }
    }
  `,
  listByCreatedAt: gql`
    query getNewssByCreatedAtQuery($startDate: String, $endDate: String) {
      getNewssByCreatedAt(startDate: $startDate, endDate: $endDate) {
        id
        topic
        description
        teacher {
          id
          firstName
          lastName
        }
        fileUrl
        createdAt
        subjects {
          id
          code
        }
        excerpt
      }
    }
  `,
  item: gql`
    query getNewsQuery($id: Int) {
      getNews(id: $id) {
        topic
        id
        description
        fileUrl
        teacher {
          id
          firstName
          lastName
        }
        excerpt
        subjects {
          id
          code
          name
        }
        createdAt
        roles
      }
    }
  `,
  broadcasts: gql`
    query {
      getBroadcasts {
        topic
        id
        description
        type
      }
    }
  `,
  create: gql`
    mutation createNewsQuery(
      $topic: String!
      $description: String!
      $excerpt: String!
      $roles: String
      $fileUrl: Upload
      $teacherId: Int!
      $classesIds: [Int]
      $subjectsIds: [Int]
    ) {
      createNews(
        topic: $topic
        roles: $roles
        excerpt: $excerpt
        description: $description
        fileUrl: $fileUrl
        teacherId: $teacherId
        classesIds: $classesIds
        subjectsIds: $subjectsIds
      ) {
        id
        topic
        roles
        description
        excerpt
        fileUrl
        teacher {
          id
          firstName
          lastName
        }
        subjects {
          id
          code
        }
        classes {
          id
          name
          code
        }
        createdAt
      }
    }
  `,
  update: gql`
    mutation updateNewsQuery(
      $id: Int
      $topic: String!
      $excerpt: String!
      $description: String!
      $roles: String
      $fileUrl: Upload
      $teacherId: Int!
      $classesIds: [Int]
      $subjectsIds: [Int]
    ) {
      updateNews(
        id: $id
        topic: $topic
        roles: $roles
        excerpt: $excerpt
        description: $description
        fileUrl: $fileUrl
        teacherId: $teacherId
        classesIds: $classesIds
        subjectsIds: $subjectsIds
      ) {
        topic
        id
        description
        excerpt
        roles
        fileUrl
        teacher {
          id
          firstName
          lastName
        }
        subjects {
          id
          code
        }
        classes {
          id
          name
          code
        }
        createdAt
      }
    }
  `,
  wipe: gql`
    mutation deleteNewsQuery($id: Int) {
      deleteNews(id: $id) {
        id
      }
    }
  `,
}
