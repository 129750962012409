import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewSubject = lazy(() => import('../views/SubjectPage/ViewSubject'));
const CreateSubject = lazy(() => import('../views/SubjectPage/CreateSubject'));
const EditSubject = lazy(() => import('../views/SubjectPage/EditSubject'));
const DeleteSubject = lazy(() => import('../views/SubjectPage/DeleteSubject'));
const SubjectPage = lazy(() => import('../views/SubjectPage/Subjects'));

function SubjectRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateSubject/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><SubjectPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewSubject/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteSubject/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditSubject/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default SubjectRoutes;
