import gql from 'graphql-tag'

export default {
  list: gql`
    query {
      getColleges {
        id
        name
        schools {
          id
          name
        }
        teacher {
          firstName
          lastName
          id
        }
      }
    }
  `,

  paginatedColleges: gql`
    query getPaginatedCollegesQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedColleges(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
            teacher {
              firstName
              lastName
              id
            }
            schools {
              id
              name
            }
          }
        }
      }
    }
  `,
  item: gql`
    query getCollegeQuery($id: Int) {
      getCollege(id: $id) {
        name
        id
        teacher {
          id
          firstName
          lastName
        }
        schools {
          id
          name
        }
      }
    }
  `,
  update: gql`
    mutation updateCollegeQuery(
      $id: Int
      $name: String!
      $schoolsIds: [Int]
      $teacherId: Int
    ) {
      updateCollege(
        id: $id
        name: $name
        schoolsIds: $schoolsIds
        teacherId: $teacherId
      ) {
        name
        id
        teacher {
          firstName
          lastName
          id
        }
        schools {
          id
          name
        }
      }
    }
  `,
  create: gql`
    mutation createCollegeQuery(
      $name: String!
      $schoolsIds: [Int]
      $teacherId: Int
    ) {
      createCollege(
        name: $name
        schoolsIds: $schoolsIds
        teacherId: $teacherId
      ) {
        id
        name
        teacher {
          id
          firstName
          lastName
        }
        schools {
          id
          name
        }
      }
    }
  `,
  wipe: gql`
    mutation deleteCollegeQuery($id: Int) {
      deleteCollege(id: $id) {
        id
      }
    }
  `,
}
