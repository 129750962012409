import gql from 'graphql-tag';

export default {
  paginatedParents: gql`query getPaginatedParentsQuery(
    $pagination: PaginationInput, $after: String,
  ) {
    getPaginatedParents(
      pagination: $pagination,
      after: $after
    ) {
      totalCount,
      pageInfo {
        endCursor,
        startCursor,
        hasNextPage,
      },
      edges {
        node {
          id,
          firstName,
          lastName,
          status,
          dob,
          regNumber,
          gender,
          students { id, firstName, lastName }
        }
      }
    }
  }`,
  list: gql`query {getParents{firstName, id}}`,
  parentsByAlphabets: gql`query getParentsByAlphabetQuery($alphabet: String, $property: String) {
    getParentsByAlphabet(alphabet: $alphabet, property: $property){
        firstName, id
    }
  }`,
  item: gql`query getParentQuery($id: Int) {
    getParent(id: $id){
      firstName, id, address, phoneNumber, email, students{ id, firstName, lastName }, regNumber, gender, lastName, nationality, dob, status, role
    }
  }`,
  update: gql`mutation updateParentQuery(
    $id: Int,
    $firstName: String!,
    $email: String!,
    $nationality: String!,
    $dob: String,
    $gender: String!,
    $lastName: String!,
    $phoneNumber: String!,
    $regNumber: String!,
    $address: String!,
    $studentsIds: [Int]) {
        updateParent(
            id: $id,
            firstName: $firstName,
            dob: $dob,
            lastName: $lastName,
            nationality: $nationality,
            gender: $gender,
            email: $email,
            phoneNumber: $phoneNumber,
            regNumber: $regNumber,
            address: $address,
            studentsIds: $studentsIds){
                firstName,
                id,
                address,
                phoneNumber,
                email,
                status,
                role,
                regNumber,
                students{ id, firstName, lastName },
                gender, lastName, nationality
        }
    }
  `,
  create: gql`mutation createParentQuery(
    $firstName: String!,
    $email: String!,
    $phoneNumber: String!,
    $nationality: String!,
    $gender: String!,
    $lastName: String!,
    $address: String!,
    $role: String!,
    $dob: String!,
    $regNumber: String!,
    $studentsIds: [Int]) {
        createParent(
            firstName: $firstName,
            lastName: $lastName,
            nationality: $nationality,
            gender: $gender,
            dob: $dob,
            email: $email,
            phoneNumber: $phoneNumber,
            address: $address,
            role: $role,
            regNumber: $regNumber,
            studentsIds: $studentsIds){
                id,
                firstName,
                address,
                phoneNumber,
                regNumber,
                email,
                role,
                students{ id, firstName, lastName },
                gender, lastName, nationality, status
        }
    }
  `,
  wipe: gql`mutation deleteParentQuery($id: Int) {
    deleteParent(id: $id){
        id
    }
  }`,
  changePassword: gql`mutation updateParentPasswordQuery(
    $regNumber: String,
    $password: String,
    $newPassword: String
) {
    updateParentPassword(regNumber: $regNumber, password: $password, newPassword: $newPassword){
        status
    }
  }`,
  parentStudentsResult: gql`query getParentStudentsResultQuery($id: Int) {
    getParentStudentsResult(id: $id){
        firstName,
        id,
        regNumber,
        email,
        phoneNumber,
        results{
            subjectCode,
            subjectId,
            creditUnit,
            exams{
                score, topic, overallMark, percentage
            },
            assessments{
                score, topic, overallMark, percentage
            }
        }
    }
  }`
};
