/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import withSearch from './withSearch'
import {
  JitsuField,
  JitsuLabel,
  JitsuMultiselectSelection,
  JitsuMultiselectToggle,
  JitsuSimpleMultiselectOptions,
} from '../../components-elements/jistu'
import { createMap } from '../../functions'
import { useMultiSelectOptionWrapper } from '../../hooks/useMultiSelectOptionWrapper'

const createProps = ({ searchResult }) => ({
  options: searchResult || [],
})

function SearchAndSelect({
  value,
  valueKey,
  lockedOptions,
  disabled,
  onChange,
  limit,
  name,
  isSingleSelect,
  textKey,
  options,
  label,
  error,
  optional,
  tooltip,
  required,
  id,
  through,
  exempt,
  hasOptionsInModal,
  enclosed,
}) {
  const [expanded, setExpanded] = useState(false)
  const [selectionsMap, setSelectionsMap] = useState(createMap(value, valueKey))
  const [lockedOptionsMap, setLockedOptionsMap] = useState(
    createMap(lockedOptions, valueKey),
  )
  const ModifiedJitsuSimpleMultiselectOptions = useMemo(
    () =>
      withSearch({
        Comp: JitsuSimpleMultiselectOptions,
        through,
        exempt,
        id: id || name,
        createProps: (param) => ({
          ...createProps(param),
          hasOptionsInModal,
        }),
      }),
    [exempt, id, name, through, hasOptionsInModal],
  )

  useEffect(() => {
    setSelectionsMap(createMap(value, valueKey))
  }, [value, valueKey])

  useEffect(() => {
    setLockedOptionsMap(createMap(lockedOptions, valueKey))
  }, [lockedOptions, valueKey])

  const toggleExpansion = () => {
    if (disabled) return
    setExpanded(!expanded)
  }

  const hideExpansion = () => {
    if (disabled) return
    setExpanded(false)
  }

  const onSelect = (selected) => {
    if (disabled || lockedOptionsMap[selected[valueKey]]) return
    if (isSingleSelect) {
      if (value[0] && selected[valueKey] === value[0][valueKey]) {
        onChange([], name)
        return
      }
      onChange([selected], name)
      return
    }
    const newSelections = selectionsMap[selected[valueKey]]
      ? value.filter((selection) => selection[valueKey] !== selected[valueKey])
      : value.concat(selected)
    if (newSelections.length > limit) return
    onChange(newSelections, name)
  }

  const { Tag, modalProps } = useMultiSelectOptionWrapper({
    onCloseModal: hideExpansion,
    expanded,
    hasOptionsInModal,
    label,
  })

  return (
    <JitsuField error={error}>
      <JitsuLabel optional={optional} tooltip={tooltip} required={required}>
        {label}
      </JitsuLabel>
      <div className="flex w-full items-center relative">
        <div className="flex flex-col items-center relative w-full">
          <>
            <div className="w-full">
              <div className="mb-2 flex border border-gray-200 bg-white rounded space-between">
                <JitsuMultiselectSelection
                  selection={value}
                  textKey={textKey}
                  valueKey={valueKey}
                  remove={onSelect}
                  clickSelectionArea={toggleExpansion}
                  id={id}
                  enclosed={enclosed}
                />
                <JitsuMultiselectToggle
                  expanded={expanded}
                  onClick={toggleExpansion}
                  disabled={disabled}
                  id={id}
                />
              </div>
              {expanded && (
                <Tag {...modalProps}>
                  <ModifiedJitsuSimpleMultiselectOptions
                    id={id}
                    options={options}
                    textKey={textKey}
                    valueKey={valueKey}
                    onClickOption={onSelect}
                    expanded={expanded}
                    lockedMap={lockedOptionsMap}
                    selectionsMap={selectionsMap}
                    has
                  />
                </Tag>
              )}
            </div>
          </>
        </div>
      </div>
    </JitsuField>
  )
}

SearchAndSelect.defaultProps = {
  value: [],
  showChecker: false,
  disabled: false,
  error: '',
  exempt: [],
  through: '',
  lockedOptions: [],
  isSingleSelect: false,
  onChange: () => {},
  options: [],
  classname: 'custom-select',
  wrapperClassname: '',
  id: 'custom-select',
  expanded: false,
  minimum: 3,
  hasOptionsInModal: true,
}

SearchAndSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  lockedOptions: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  limit: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isSingleSelect: PropTypes.bool.isRequired,
  through: PropTypes.string,
  exempt: PropTypes.array,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
  hasOptionsInModal: PropTypes.bool,
  enclosed: PropTypes.bool,
}

export default SearchAndSelect
