import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { classNames } from '../functions'
import useAppTheme from '../hooks/useAppTheme'

function JitsuTab({
  children,
  headers,
  selected,
  onClickTabHeader,
  className,
  leftTitle,
}) {
  const theme = useAppTheme()
  const Tag = leftTitle ? 'div' : Fragment
  const props = leftTitle ? { className: 'flex justify-between w-full' } : {}
  return (
    <div className={`${className} h-full pb-14`}>
      <Tag {...props}>
        {leftTitle && <div className="text-sm pt-4 px-2">{leftTitle}</div>}
        {headers.length > 1 && (
          <div className="flex cursor-pointer overflow-none h-8">
            {headers.map(({ text, value, TabIcon }) => (
              <div
                role="button"
                className={classNames(
                  selected === value
                    ? `${theme.textPrimary700} ${theme.bgPrimary100}`
                    : `text-gray-500 ${theme.hoverTextPrimary500}`,
                  'px-3 py-2 font-medium text-xs rounded-md flex',
                )}
                key={value}
                onClick={() => onClickTabHeader(value)}
                id={text.split(' ').join('-')}
              >
                {TabIcon && (
                  <TabIcon
                    className="-ml-0.5 mr-1 h-4 w-4"
                    aria-hidden="true"
                  />
                )}
                {text}
              </div>
            ))}
          </div>
        )}
      </Tag>
      {children}
    </div>
  )
}

JitsuTab.defaultProps = {
  headers: [],
  selected: '',
  onClickTabHeader: () => {},
  children: <div />,
  className: '',
}

JitsuTab.propTypes = {
  children: PropTypes.node.isRequired,
  headers: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClickTabHeader: PropTypes.func.isRequired,
  leftTitle: PropTypes.any,
}

export default JitsuTab
