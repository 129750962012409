const PAGES = {
  assessments: 'assessments',
  students: 'students',
  parents: 'parents',
  teachers: 'teachers',
  notes: 'notes',
  subjects: 'subjects',
  news: 'news',
  periods: 'periods',
  classes: 'classes',
  schools: 'schools',
  requests: 'requests',
  registration: 'registration',
  grades: 'grades',
  dashboard: 'dashboard',
  changeSchool: 'changeSchool',
  performance: 'performance',
  profile: 'profile',
  settings: 'settings',
  invoices: 'invoices',
  colleges: 'colleges',
  transcript: 'transcript',
  questions: 'questions',
  payments: 'payments',
  qa: 'qa',
  scoresheet: 'scoresheet',
}

export default PAGES
