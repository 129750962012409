import {
  MULTISELECT,
  SEARCH_MULTISELECT,
  SELECT_INPUT,
  TEXT_INPUT,
} from '../../../constants/fieldTypes'
import { textChange } from '../../../functions/textChange'

export const INVOICE_FOR = {
  CLASSES: 'CLASSES',
  STUDENTS: 'STUDENTS',
}

export const fields = [
  {
    name: 'name',
    type: TEXT_INPUT,
    label: 'Name',
    placeholder: 'Name of invoice',
    required: true,
    className: 'col-span-2',
  },
  {
    name: 'code',
    type: TEXT_INPUT,
    label: 'Code',
    placeholder: 'INV001',
    required: true,
  },
  {
    name: 'amount',
    type: TEXT_INPUT,
    label: 'Amount',
    placeholder: '10000',
    required: true,
  },
  {
    name: 'description',
    type: TEXT_INPUT,
    label: 'Description',
    placeholder: 'Reason for invoice',
    required: true,
    className: 'col-span-2',
  },
  {
    name: 'paymentSubAccount',
    type: SELECT_INPUT,
    label: 'Payment account',
    valueKey: 'id',
    textKey: 'text',
    options: ({ paymentSubAccounts }) =>
      (paymentSubAccounts.getPaymentSubAccounts || []).map((acc) => ({
        ...acc,
        text: `${acc.name} (${acc.accountNumber})`,
        value: acc.id,
      })),
  },
  {
    name: 'invoiceFor',
    type: SELECT_INPUT,
    label: 'Invoice for',
    required: true,
    options: () => [
      { text: textChange('Classes'), value: INVOICE_FOR.CLASSES },
      { text: textChange('Students'), value: INVOICE_FOR.STUDENTS },
    ],
  },
  {
    name: 'classes',
    type: MULTISELECT,
    label: 'Classes',
    optional: true,
    valueKey: 'name',
    textKey: 'text',
    options: () => [],
    className: 'col-span-2',
    condition: ({ form }) => form.invoiceFor === INVOICE_FOR.CLASSES,
  },
  {
    required: true,
    name: 'students',
    type: SEARCH_MULTISELECT,
    label: `${textChange('Students')}`,
    placeholder: 'Students',
    displayTextFcn: (selection) => selection.text || selection.firstName,
    valueKey: 'actualId',
    textKey: 'text',
    through: 'student',
    className: 'col-span-2',
    condition: ({ form }) => form.invoiceFor === INVOICE_FOR.STUDENTS,
  },
]
