import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewSchool = lazy(() => import('../views/SchoolPage/ViewSchool'));
const CreateSchool = lazy(() => import('../views/SchoolPage/CreateSchool'));
const EditSchool = lazy(() => import('../views/SchoolPage/EditSchool'));
const DeleteSchool = lazy(() => import('../views/SchoolPage/DeleteSchool'));
const SchoolPage = lazy(() => import('../views/SchoolPage/Schools'));

function SchoolRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateSchool/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><SchoolPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewSchool/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteSchool/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditSchool/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default SchoolRoutes;
