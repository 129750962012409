/* eslint-disable max-len */
import PropTypes from 'prop-types'
import React from 'react'
import { ExclamationTriangleIconOutline } from '../assets/icons'
import { COLOR_TYPES } from '../constants/colorTypes'
import useAppTheme from '../hooks/useAppTheme'

function JitsuAlert({ headerText, children, type, className }) {
  const theme = useAppTheme()
  const typeColorMap = {
    [COLOR_TYPES.message]: {
      containerBg: theme.bgPrimary50,
      iconColor: theme.textPrimary400,
      AlertIcon: ExclamationTriangleIconOutline,
      headerTextColor: theme.textPrimary800,
      subTextColor: theme.textPrimary700,
    },
    [COLOR_TYPES.danger]: {
      containerBg: 'bg-red-50',
      iconColor: 'text-red-400',
      AlertIcon: ExclamationTriangleIconOutline,
      headerTextColor: 'text-red-800',
      subTextColor: 'text-red-700',
    },
    [COLOR_TYPES.success]: {
      containerBg: 'bg-green-50',
      iconColor: 'text-green-400',
      AlertIcon: ExclamationTriangleIconOutline,
      headerTextColor: 'text-green-800',
      subTextColor: 'text-green-700',
    },
    [COLOR_TYPES.warning]: {
      containerBg: 'bg-yellow-50',
      iconColor: 'text-yellow-400',
      AlertIcon: ExclamationTriangleIconOutline,
      headerTextColor: 'text-yellow-800',
      subTextColor: 'text-yellow-700',
    },
    [COLOR_TYPES.info]: {
      containerBg: theme.bgPrimary50,
      iconColor: theme.textPrimary400,
      AlertIcon: ExclamationTriangleIconOutline,
      headerTextColor: theme.textPrimary800,
      subTextColor: theme.textPrimary700,
    },
  }

  const { AlertIcon, containerBg, headerTextColor, iconColor, subTextColor } =
    typeColorMap[type]
  return (
    <div className={`rounded-md ${containerBg} p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertIcon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          {headerText && (
            <h3 className={`text-sm font-medium mb-2 ${headerTextColor}`}>
              {headerText}
            </h3>
          )}
          {children && (
            <div
              className={`${
                headerText ? 'text-xs' : 'text-sm'
              } ${subTextColor}`}
            >
              <div>{children}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

JitsuAlert.defaultProps = {
  headerText: '',
  type: 'info',
  children: '',
  className: '',
}

JitsuAlert.propTypes = {
  headerText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
}

export default JitsuAlert
