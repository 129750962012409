import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

function ClickOutside({ onClickOutside, element: WrapperElement, children, className }) {
  const wrapperRef = useRef(null);
  const handleClickOutside = useCallback((event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      onClickOutside();
    }
  }, [wrapperRef, onClickOutside]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }, [handleClickOutside]);

  return (<WrapperElement className={ className } ref={ wrapperRef }>
    { children }
  </WrapperElement>);
}

ClickOutside.defaultProps = {
  onClickOutside: () => {},
  className: '',
  element: 'div'
};

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  element: PropTypes.any,
  children: PropTypes.any.isRequired,
  className: PropTypes.any.isRequired
};

export default ClickOutside;
