import gql from 'graphql-tag'

const parentStudentRemarkFrag = `
  createdAt,
  updatedAt,
  topic,
  description,
  fileUrl,
  parent { firstName, lastName, id, regNumber }
  student { firstName, lastName, id, regNumber }
`

export default {
  list: gql`query {
    getParentStudentRemarks{
      id,
      ${parentStudentRemarkFrag}
    }
  }`,
  item: gql`
    query getParentStudentRemarkQuery($id: Int) {
      getParentStudentRemark(id: $id) {
        id,
        ${parentStudentRemarkFrag}
      }
    }
  `,
  create: gql`
    mutation createParentStudentRemarkQuery(
      $description: String!
      $topic: String!
      $fileUrl: Upload
      $studentId: Int!
    ) {
      createParentStudentRemark(
        description: $description
        topic: $topic
        fileUrl: $fileUrl
        studentId: $studentId
      ) {
        id,
        ${parentStudentRemarkFrag}
      }
    }
  `,
  update: gql`
    mutation updateParentStudentRemarkQuery(
      $id: Int
      $description: String!
      $topic: String!
      $fileUrl: Upload
      $studentId: Int!
    ) {
      updateParentStudentRemark(
        id: $id,
        description: $description
        topic: $topic
        fileUrl: $fileUrl
        studentId: $studentId
      ) {
        id,
        ${parentStudentRemarkFrag}
      }
    }
  `,

  wipe: gql`
    mutation deleteParentStudentRemarkQuery($id: Int) {
      deleteParentStudentRemark(id: $id) {
        id
      }
    }
  `,
  paginatedParentStudentRemarks: gql`
    query getPaginatedParentStudentRemarksQuery(
      $pagination: PaginationInput
      $studentId: Int
      $after: String
    ) {
      getPaginatedParentStudentRemarks(
        pagination: $pagination
        after: $after
        studentId: $studentId
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            topic
            createdAt
            updatedAt
            parent {
              id
              firstName
              lastName
              regNumber
            }
          }
        }
      }
    }
  `,
}
