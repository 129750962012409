import gql from 'graphql-tag'

export default {
  paginatedTeachers: gql`
    query getPaginatedTeachersQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedTeachers(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            firstName
            lastName
            qualification
            status
            dob
            regNumber
            gender
            subjects {
              id
              code
            }
            role
          }
        }
      }
    }
  `,
  results: gql`
    query getTeacherResultQuery($teacherId: Int) {
      getTeacherResult(teacherId: $teacherId) {
        subjectId
        code
        highestScore
        lowestScore
        averageScore
      }
    }
  `,
  item: gql`
    query getTeacherQuery($id: Int) {
      getTeacher(id: $id) {
        lastName
        id
        qualification
        firstName
        dob
        gender
        address
        phoneNumber
        regNumber
        email
        nationality
        regNumber
        classes {
          id
          name
        }
        subjects {
          id
          code
          name
        }
        schools {
          id
          name
        }
        previousWorkPlace
        role
        status
      }
    }
  `,
  changePassword: gql`
    mutation updateTeacherPasswordQuery(
      $regNumber: String
      $password: String
      $newPassword: String
    ) {
      updateTeacherPassword(
        regNumber: $regNumber
        password: $password
        newPassword: $newPassword
      ) {
        status
      }
    }
  `,
  create: gql`
    mutation createTeacherQuery(
      $firstName: String!
      $lastName: String!
      $qualification: String!
      $email: String!
      $phoneNumber: String!
      $regNumber: String!
      $dob: String!
      $gender: String!
      $address: String!
      $nationality: String!
      $previousWorkPlace: String!
      $photoUrl: String!
      $role: String!
      $subjectsIds: [Int]
      $schoolsIds: [Int]
    ) {
      createTeacher(
        lastName: $lastName
        firstName: $firstName
        qualification: $qualification
        email: $email
        phoneNumber: $phoneNumber
        dob: $dob
        gender: $gender
        address: $address
        nationality: $nationality
        previousWorkPlace: $previousWorkPlace
        role: $role
        photoUrl: $photoUrl
        regNumber: $regNumber
        schoolsIds: $schoolsIds
        subjectsIds: $subjectsIds
      ) {
        lastName
        id
        qualification
        firstName
        dob
        gender
        address
        previousWorkPlace
        phoneNumber
        email
        nationality
        subjects {
          id
          code
        }
        schools {
          id
          name
        }
        role
        status
      }
    }
  `,
  update: gql`
    mutation updateTeacherQuery(
      $id: Int
      $firstName: String!
      $lastName: String!
      $qualification: String!
      $email: String!
      $role: String!
      $phoneNumber: String!
      $regNumber: String!
      $dob: String!
      $gender: String!
      $previousWorkPlace: String!
      $address: String!
      $nationality: String!
      $schoolsIds: [Int]
      $subjectsIds: [Int]
    ) {
      updateTeacher(
        id: $id
        lastName: $lastName
        firstName: $firstName
        qualification: $qualification
        email: $email
        role: $role
        regNumber: $regNumber
        phoneNumber: $phoneNumber
        previousWorkPlace: $previousWorkPlace
        schoolsIds: $schoolsIds
        dob: $dob
        gender: $gender
        address: $address
        nationality: $nationality
        subjectsIds: $subjectsIds
      ) {
        lastName
        id
        qualification
        firstName
        regNumber
        dob
        gender
        role
        address
        phoneNumber
        previousWorkPlace
        email
        nationality
        subjects {
          id
          code
        }
        schools {
          id
          name
        }
        status
      }
    }
  `,
  wipe: gql`
    mutation deleteTeacherQuery($id: Int) {
      deleteTeacher(id: $id) {
        id
      }
    }
  `,
  teacherSubjects: gql`
    query getTeacherSubjectsQuery($userId: Int) {
      getTeacherSubjects(userId: $userId) {
        code
        id
        isMandatory
        class {
          id
          name
          code
        }
        subject {
          id
          name
          code
        }
      }
    }
  `,
  ledSubjects: gql`
    query getSubjectsLedByTeacherQuery($teacherId: Int) {
      getSubjectsLedByTeacher(teacherId: $teacherId) {
        code
        id
      }
    }
  `,
  teacherSubjectResults: gql`
    query getTeacherSubjectResultsQuery($teacherId: Int, $subjectId: Int) {
      getTeacherSubjectResults(teacherId: $teacherId, subjectId: $subjectId) {
        name
        studentId
        regNumber
        email
        phoneNumber
        results {
          code
          subjectId
          highestScore
          lowestScore
          creditUnit
          assessments {
            score
            topic
            overallMark
            percentage
          }
        }
      }
    }
  `,
}
