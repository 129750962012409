import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewNews = lazy(() => import('../views/NewsPage/ViewNews'));
const CreateNews = lazy(() => import('../views/NewsPage/CreateNews'));
const EditNews = lazy(() => import('../views/NewsPage/EditNews'));
const DeleteNews = lazy(() => import('../views/NewsPage/DeleteNews'));
const NewsPage = lazy(() => import('../views/NewsPage/NewsList'));

function NewsRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateNews/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><NewsPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewNews/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteNews/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditNews/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default NewsRoutes;
