import {
  ADMIN,
  CASHIER_ADMIN,
  OVERSEER,
  PARENT,
  SEER,
  STUDENT,
  SUBJECT_ADMIN,
  TEACHER,
} from '../constants/roles'
import { PRIMARY, TERTIARY } from '../constants/schoolTypes'
import { getCurrentUser } from './user'

const tertiarySubstitute = {
  classes: 'departments',
  class: 'department',
  Class: 'Department',
  Classes: 'Departments',

  teacher: 'lecturer',
  teachers: 'lecturers',
  Teacher: 'Lecturer',
  Teachers: 'Lecturers',

  subject: 'course',
  subjects: 'courses',
  Subject: 'Course',
  Subjects: 'Courses',

  note: 'course content',
  notes: 'course contents',
  Note: 'Course content',
  Notes: 'Course contents',

  School: 'University',
  Schools: 'Universities',
  school: 'university',
  schools: 'universities',

  news: 'notice board',
  News: 'Notice board',

  Record: 'Record',
  Remark: 'Remark',
}

const primarySubstitute = {
  semester: 'term',
  Semester: 'Term',

  colleges: 'class groups',
  college: 'class group',
  College: 'Class group',
  Colleges: 'Class groups',

  note: 'learning content',
  notes: 'learning contents',
  Note: 'Learning content',
  Notes: 'Learning contents',

  news: 'notice board',
  News: 'Notice board',

  student: 'pupil',
  students: 'pupils',
  Student: 'Pupil',
  Students: 'Pupils',
}

const textChange = (text) => {
  const { schoolType } = getCurrentUser()
  const substitutes = {
    [TERTIARY]: tertiarySubstitute,
    [PRIMARY]: primarySubstitute,
  }[schoolType]
  if (!substitutes) return text
  return substitutes[text] || text
}

export const ROLES_AS_TEXT = {
  [ADMIN]: 'Admin',
  [CASHIER_ADMIN]: 'Cashier admin',
  [STUDENT]: textChange('Student'),
  [TEACHER]: textChange('Teacher'),
  [PARENT]: 'Parent',
  [SEER]: 'Seer',
  [OVERSEER]: 'Overseer',
  [SUBJECT_ADMIN]: `${textChange('Subject')} lead`,
}

export { textChange }
