import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import useAppTheme from '../hooks/useAppTheme';
import JitsuIcon from './JitsuIcon';

const TooltipDiv = styled.div`
/* Tooltip container */
.tooltip {
  position: relative;
  display: flex;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  margin: 4px;

  &.left {
    top: -5px;
    right: 105%;
  }

  &.right {
    top: -5px;
    left: 105%;
  }

  &.top {
    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px
  }
  &.down {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px; 
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
`;

function JitsuTooltip({ tooltipText, iconProps, position, hideIcon, children, hideTooltip }) {
  const { textPrimary600, hoverTextPrimary700 } = useAppTheme
  return (
    <TooltipDiv>
      <div className="tooltip">
        { children }
        { !hideIcon && <JitsuIcon 
          name="info"
          className={`h-4 w-4 fill-current ${hoverTextPrimary700} ${textPrimary600} -mb-5 ml-1 z-10 cursor-pointer`}
          { ...iconProps }
        /> }
        { hideTooltip && <span className={ `bg-black bg-opacity-75 tooltiptext ${position} text-xs` }>{ tooltipText }</span> }
      </div>     
    </TooltipDiv>
  );
}

JitsuTooltip.defaultProps = {
  iconProps: {},
  tooltipText: '',
  hideIcon: false,
  hideTooltip: false,
  position: 'bottom',
  children: <></>
};

JitsuTooltip.propTypes = {
  tooltipText: PropTypes.any.isRequired,
  iconProps: PropTypes.object.isRequired,
  position: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  hideIcon: PropTypes.bool.isRequired,
  hideTooltip: PropTypes.bool.isRequired
};

export default JitsuTooltip;
