import PropTypes from 'prop-types'
import React from 'react'
import { CheckCircleIconOutline, LockClosedIconOutline } from '../assets/icons'
import useAppTheme from '../hooks/useAppTheme'

function JitsuMultiselectOption({
  option,
  onClickOption,
  selected,
  locked,
  children,
  searcher,
  id,
}) {
  const theme = useAppTheme()
  return (
    <div
      role="button"
      id={id}
      className={`cursor-pointer w-full border-gray-100 text-xs rounded-t border-b 
  ${searcher ? '' : theme.hoverBgPrimary100}`}
      onClick={() => {
        if (!locked) {
          onClickOption(option)
        }
      }}
    >
      <div
        className={`flex w-full items-center p-1 pl-4 border-transparent ${
          locked ? 'text-gray-400' : ''
        } relative ${theme.borderPrimary600}`}
      >
        <div className={`w-full ${searcher ? '' : 'items-center flex'}`}>
          {locked && (
            <LockClosedIconOutline className="-ml-0.5 mr-2 h-4 w-4 font-medium text-grey-400" />
          )}
          <div className="mx-2 leading-6 flex-1">{children}</div>
          {selected && (
            <CheckCircleIconOutline
              className={`-ml-0.5 mr-2 h-4 w-4 font-medium ${theme.textPrimary600}`}
            />
          )}
        </div>
      </div>
    </div>
  )
}

JitsuMultiselectOption.defaultProps = {
  selected: false,
  locked: false,
  searcher: false,
  children: '',
  id: '',
  option: {},
  onClickOption: () => {},
}

JitsuMultiselectOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  searcher: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  onClickOption: PropTypes.func.isRequired,
}

export default JitsuMultiselectOption
