import PropTypes from 'prop-types'
import React from 'react'
import useAppTheme from '../hooks/useAppTheme'

function JitsuTableRow({ children, onClick, className, isLink, ...rest}) {
  const { hoverBgPrimary50 } = useAppTheme()
  return (
    <tr
      className={`${hoverBgPrimary50} divide-x divide-gray-200 ${className}`}
      onClick={onClick}
      role={isLink && 'button'}
      {...rest}
    >
      {children}
    </tr>
  )
}

JitsuTableRow.defaultProps = {
  onClick: () => {},
  children: <td />,
  className: '',
}

JitsuTableRow.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isLink: PropTypes.bool
}

export default JitsuTableRow
