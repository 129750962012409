import gql from 'graphql-tag';

export default {
  list: gql`query {getPeriods{name, id, startTime, endTime, date, subject{id, code} }}`,

  subjectPeriods: gql`query getSubjectPeriodQuery($subjectId: Int) {
      getSubjectPeriods(subjectId: $subjectId) {
          name, id
      }
  }`,

  item: gql`query getPeriodQuery($id: Int) {
      getPeriod(id: $id){
          name,
          id,
          startTime,
          endTime,
          date,
          type,
          subject { id, code, name },
          students{ id, firstName, lastName },
          notes{ id, topic },
          joinUrl,
          startUrl,
      }
  }`,

  update: gql`mutation updatePeriodQuery(
      $id: Int,
      $name: String!,
      $endTime: String!,
      $startTime: String!,
      $date: String!,
      $subjectId: Int,
      $meeting: Int,
      $notesIds: [Int],
      $propagate: Int,
      $studentsIds: [Int]) {
          updatePeriod(
              id: $id,
              name: $name,
              startTime: $startTime,
              date: $date,
              endTime: $endTime,
              meeting: $meeting,
              propagate: $propagate,
              subjectId: $subjectId,
              notesIds: $notesIds,
              studentsIds: $studentsIds){
                  name,
                  id,
                  startTime,
                  endTime,
                  joinUrl,
                  startUrl,
                  date,
                  notes{ id, topic },
                  subject { id, code, name  },
                  students { id, firstName, lastName }
          }
      }
  `,

  create: gql`mutation createPeriodQuery(
      $name: String!,
      $endTime: String!,
      $startTime: String!,
      $date: String!,
      $endRepititionDate: String!,
      $meeting: Int,
      $subjectsIds: [Int],
      $type: String,
      $notesIds: [Int],
      $studentsIds: [Int]) {
          createPeriod(
              name: $name,
              meeting: $meeting,
              startTime: $startTime,
              date: $date,
              endRepititionDate: $endRepititionDate,
              endTime: $endTime,
              subjectsIds: $subjectsIds,
              type: $type,
              notesIds: $notesIds,
              studentsIds: $studentsIds) {
                  id,
                  name,
                  startTime,
                  date,
                  endTime,
                  joinUrl,
                  startUrl,
                  date,
                  type,
                  notes{ id, topic },
                  subject { id, code, name  },
                  students { id, firstName, lastName }
          }
      }
  `,

  wipe: gql`mutation deletePeriodQuery($id: Int, $propagate: Int) {
      deletePeriod(id: $id, propagate: $propagate){
          id
      }
  }`,

  periodsByDateRange: gql`query getPeriodsByDateRangeQuery($endDate: String, $startDate: String){
      getPeriodsByDateRange(startDate: $startDate, endDate: $endDate)
        { id, endTime, startTime, date, subject{ id, code }, name }
  }`,

  attendanceRecord: gql`query getStudentAttendanceRecordQuery($studentId: Int) {
      getStudentAttendanceRecord(studentId: $studentId) {
          id, name, attendanceRecord, code
      }
  }`,
  subjectAttendanceRecord: gql`query getStudentSubjectAttendanceRecordQuery($studentId: Int, $subjectId: Int) {
    getStudentSubjectAttendanceRecord(studentId: $studentId, subjectId: $subjectId) {
        id, name, attendanceRecord
    }
  }`,
  assessmentAttendanceRecord: gql`query getAttendanceRecordForAssessmentQuery($studentId: Int, $assessmentId: Int) {
    getAttendanceRecordForAssessment(studentId: $studentId, assessmentId: $assessmentId)
  }`
};
