/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React from 'react'
import JitsuMultiselectOption from './JitsuMultiselectOption'

function JitsuMultiselectOptions({
  options,
  expanded,
  onClickOption,
  textKey,
  lockedMap,
  valueKey,
  selectionsMap,
  children,
  id,
  hasOptionsInModal,
}) {
  const className = expanded ? 'block' : 'hidden'
  const classNameForOptionsContainer = hasOptionsInModal
    ? 'relative'
    : 'absolute'
  const classNameForOptionsInnerContainer = hasOptionsInModal
    ? 'h-128'
    : 'h-48 left-2 top-120'
  return (
    <div id={`${id}_options`} className="flex items-center relative w-full">
      <div className={`${className} w-full`}>
        <div
          className={`${classNameForOptionsContainer} shadow bg-white z-40 w-full rounded max-h-select`}
        >
          <div
            className={`flex flex-col w-full ${classNameForOptionsInnerContainer}`}
          >
            {children}
            <div className="overflow-y-auto">
              {options.map((option) => (
                <JitsuMultiselectOption
                  key={option[valueKey]}
                  id={`${id}_option_${option[valueKey]}`}
                  locked={!!lockedMap[option[valueKey]]}
                  onClickOption={onClickOption}
                  selected={!!selectionsMap[option[valueKey]]}
                  option={option}
                >
                  {option[textKey]}
                </JitsuMultiselectOption>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

JitsuMultiselectOptions.defaultProps = {
  selectionsMap: {},
  children: '',
  id: '',
}

JitsuMultiselectOptions.propTypes = {
  options: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  lockedMap: PropTypes.object.isRequired,
  selectionsMap: PropTypes.object.isRequired,
  onClickOption: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  hasOptionsInModal: PropTypes.bool,
}

export default JitsuMultiselectOptions
