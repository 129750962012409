import PropTypes from 'prop-types';
import React from 'react';
import useAppTheme from '../hooks/useAppTheme';

function Checkbox({ value, name, onChange, label, id }) {
  const theme = useAppTheme();
  return <div>
  <input
    type="checkbox"
    defaultChecked={ value === 1 }
    onClick={ () => { onChange(value === 1 ? 0 : 1, name); } }
    id={ id || name }
    className={`${theme.focusRingPrimary500} h-4 w-4 ${theme.textPrimary600} border-gray-300 rounded`}
  />
  { label && <label htmlFor={ name } className="ml-2">{ label }</label> }
</div>
}

Checkbox.defaultProps = {
  value: 0
};

Checkbox.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default Checkbox;
