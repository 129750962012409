import gql from 'graphql-tag'

export default {
  dAssessments: gql`
    query getPersonalAssessmentsQuery($userId: Int, $userRole: String) {
      getPersonalAssessments(userId: $userId, userRole: $userRole) {
        id
        teacher {
          id
          firstName
          lastName
        }
        topic
        deadline
        overallMark
        fileUrl
        subjects {
          id
          code
        }
      }
    }
  `,
  item: gql`
    query getAssessmentQuery($id: Int) {
      getAssessment(id: $id) {
        topic
        id
        description
        fileUrl
        teacher {
          id
          firstName
          lastName
        }
        subjects {
          id
          code
          name
        }
        overallMark
        deadline
        type
        createdAt
        updatedAt
        enforceAttendanceRule
        enforceSubmission
        start
        duration
      }
    }
  `,
  itemForTeachers: gql`
    query getAssessmentQuery($id: Int) {
      getAssessment(id: $id) {
        topic
        id
        description
        fileUrl
        teacher {
          id
          firstName
          lastName
        }
        subjects {
          id
          code
        }
        overallMark
        deadline
        type
        createdAt
        updatedAt
        enforceAttendanceRule
        enforceSubmission
        start
        duration
        questions {
          id
          name
        }
      }
    }
  `,
  submission: gql`
    query getSubmission($assessmentId: Int, $subjectId: Int, $studentId: Int) {
      getSubmission(
        studentId: $studentId
        assessmentId: $assessmentId
        subjectId: $subjectId
      ) {
        fileUrl
        score
        comment
        submissionText
      }
    }
  `,
  list: gql`
    query getAssessmentsQuery(
      $startDate: String
      $endDate: String
      $pagination: PaginationInput
      $after: String
    ) {
      getAssessments(
        startDate: $startDate
        endDate: $endDate
        pagination: $pagination
        after: $after
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            topic
            createdAt
            teacher {
              id
              firstName
              lastName
            }
            subjects {
              id
              code
              name
            }
            deadline
            overallMark
            type
            duration
          }
        }
      }
    }
  `,
  assessmentCompletionPercentage: gql`
    query {
      getAssessmentCompletionPercentage {
        completed
        uncompleted
      }
    }
  `,
  listByCreatedAt: gql`
    query getAssessmentsByDeadlineQuery($startDate: String, $endDate: String) {
      getAssessmentsByDeadline(startDate: $startDate, endDate: $endDate) {
        topic
        id
        description
        fileUrl
        teacher {
          id
          firstName
          lastName
        }
        subjects {
          id
          code
          name
        }
        overallMark
        deadline
        completed
      }
    }
  `,
  create: gql`
    mutation createAssessmentQuery(
      $topic: String!
      $type: String!
      $description: String!
      $deadline: String!
      $start: String
      $fileUrl: Upload
      $teacherId: Int!
      $enforceAttendanceRule: Int
      $enforceSubmission: Int
      $duration: Int
      $overallMark: Int!
      $questionsIds: [Int]
      $subjectsIds: [Int]
    ) {
      createAssessment(
        topic: $topic
        type: $type
        description: $description
        fileUrl: $fileUrl
        deadline: $deadline
        duration: $duration
        start: $start
        teacherId: $teacherId
        questionsIds: $questionsIds
        overallMark: $overallMark
        enforceAttendanceRule: $enforceAttendanceRule
        enforceSubmission: $enforceSubmission
        subjectsIds: $subjectsIds
      ) {
        id
        topic
        description
        fileUrl
        type
        deadline
        enforceAttendanceRule
        enforceSubmission
        overallMark
        teacher {
          id
          firstName
          lastName
        }
        questions {
          id
          name
        }
        duration
        subjects {
          id
          code
        }
        createdAt
        updatedAt
        start
      }
    }
  `,
  update: gql`
    mutation updateAssessmentQuery(
      $id: Int
      $topic: String!
      $type: String!
      $description: String!
      $deadline: String!
      $start: String!
      $fileUrl: Upload!
      $duration: Int
      $teacherId: Int!
      $enforceAttendanceRule: Int
      $enforceSubmission: Int
      $overallMark: Int!
      $questionsIds: [Int]
      $subjectsIds: [Int]
    ) {
      updateAssessment(
        id: $id
        topic: $topic
        type: $type
        description: $description
        enforceAttendanceRule: $enforceAttendanceRule
        enforceSubmission: $enforceSubmission
        fileUrl: $fileUrl
        deadline: $deadline
        duration: $duration
        start: $start
        teacherId: $teacherId
        overallMark: $overallMark
        questionsIds: $questionsIds
        subjectsIds: $subjectsIds
      ) {
        topic
        id
        description
        fileUrl
        deadline
        start
        enforceAttendanceRule
        enforceSubmission
        type
        overallMark
        teacher {
          id
          firstName
          lastName
        }
        subjects {
          id
          code
        }
        createdAt
        updatedAt
        questions {
          id
          name
        }
        duration
        start
      }
    }
  `,
  wipe: gql`
    mutation deleteAssessmentQuery($id: Int) {
      deleteAssessment(id: $id) {
        id
      }
    }
  `,
  getAssessmentScores: gql`
    query getAssessmentScoresQuery($subjectId: Int, $assessmentId: Int) {
      getAssessmentScores(subjectId: $subjectId, assessmentId: $assessmentId) {
        student {
          id
        }
        score
        fileUrl
        comment
        submissionText
      }
    }
  `,
  submitAssessmentScores: gql`
    mutation submitAssessmentScoresQuery($scores: [AssessmentScoreInput]) {
      submitAssessmentScores(scores: $scores) {
        student {
          id
        }
        score
        fileUrl
        comment
      }
    }
  `,
  submitSubjectWorkPercentages: gql`
    mutation submitSubjectWorkPercentagesQueries($portions: [PortionInput]) {
      submitSubjectWorkPercentages(portions: $portions) {
        id
        type
        percentage
      }
    }
  `,
  studentSubmitAssessment: gql`
    mutation submitAssessmentQuery(
      $subjectId: Int!
      $studentId: Int!
      $assessmentId: Int!
      $fileUrl: Upload
      $submissionText: String
    ) {
      submitAssessment(
        subjectId: $subjectId
        studentId: $studentId
        assessmentId: $assessmentId
        submissionText: $submissionText
        fileUrl: $fileUrl
      ) {
        text
        status
      }
    }
  `,
  createComment: gql`
    mutation createCommentQuery(
      $subjectId: Int!
      $studentId: Int!
      $assessmentId: Int!
      $comment: String
    ) {
      createComment(
        subjectId: $subjectId
        studentId: $studentId
        assessmentId: $assessmentId
        comment: $comment
      ) {
        id
        comment
      }
    }
  `,
  getComments: gql`
    query getPaginatedCommentsQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedComments(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            comment
            createdAt
            teacher {
              id
              firstName
              lastName
              regNumber
            }
          }
        }
      }
    }
  `,
}
