import gql from 'graphql-tag'

export default {
  getSubjectStudents: gql`
    query getSubjectStudentsQuery($subjectId: Int, $status: String) {
      getStudentsBySubject(subjectId: $subjectId, status: $status) {
        lastName
        id
        firstName
        regNumber
      }
    }
  `,
  list: gql`
    query {
      getSubjects {
        code
        id
        name
        tag {
          name
          id
        }
      }
    }
  `,

  paginatedList: gql`
    query getPaginatedSubjectsQuery(
      $pagination: PaginationInput
      $after: String
    ) {
      getPaginatedSubjects(pagination: $pagination, after: $after) {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            id
            name
            code
            creditUnit
            categories {
              id
              name
            }
            leadTeacher {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `,
  item: gql`
    query getSubjectQuery($id: Int) {
      getSubject(id: $id) {
        code
        name
        id
        description
        tag {
          name
          id
        }
        classes {
          id
          name
          code
        }
        teachers {
          id
          firstName
          lastName
        }
        categories {
          id
          name
        }
        schools {
          id
          name
        }
        leadTeacher {
          id
          firstName
          lastName
        }
        students {
          id
          firstName
          lastName
        }
        creditUnit
      }
    }
  `,
  update: gql`
    mutation updateSubjectQuery(
      $id: Int!
      $code: String
      $description: String
      $creditUnit: Int
      $tag: TagInput
      $teacherId: Int!
      $categoriesIds: [Int]
      $name: String!
      $teachersIds: [Int]
      $schoolsIds: [Int]
      $studentsIds: [Int]
    ) {
      updateSubject(
        id: $id
        tag: $tag
        code: $code
        description: $description
        categoriesIds: $categoriesIds
        teacherId: $teacherId
        name: $name
        creditUnit: $creditUnit
        teachersIds: $teachersIds
        schoolsIds: $schoolsIds
        studentsIds: $studentsIds
      ) {
        code
        name
        id
        description
        classes {
          id
          name
          code
        }
        leadTeacher {
          id
          firstName
          lastName
        }
        categories {
          id
          name
        }
        schools {
          id
          name
        }
        tag {
          name
          id
        }
        creditUnit
        students {
          id
        }
        teachers {
          id
          firstName
        }
      }
    }
  `,
  create: gql`
    mutation createSubjectQuery(
      $code: String!
      $name: String!
      $description: String!
      $tag: TagInput
      $classesIds: [Int]
      $creditUnit: Int!
      $teacherId: Int!
      $teachersIds: [Int]
      $schoolsIds: [Int]
      $categoriesIds: [Int]
      $studentsIds: [Int]
    ) {
      createSubject(
        code: $code
        tag: $tag
        description: $description
        classesIds: $classesIds
        categoriesIds: $categoriesIds
        teacherId: $teacherId
        name: $name
        creditUnit: $creditUnit
        teachersIds: $teachersIds
        schoolsIds: $schoolsIds
        studentsIds: $studentsIds
      ) {
        id
        code
        description
        name
        tag {
          id
          name
        }
        creditUnit
        classes {
          id
          name
          code
        }
        categories {
          id
          name
        }
        schools {
          id
          name
        }
        students {
          id
        }
        teachers {
          id
          firstName
        }
        leadTeacher {
          id
          firstName
          lastName
        }
      }
    }
  `,
  wipe: gql`
    mutation deleteSubjectQuery($id: Int) {
      deleteSubject(id: $id) {
        id
      }
    }
  `,
  subjectNotes: gql`
    query getSubjectNotesQuery($subjectId: Int) {
      getSubjectNotes(subjectId: $subjectId) {
        id
        teacher {
          id
          firstName
          lastName
        }
        description
        fileUrl
        createdAt
        topic
        subjects {
          name
          id
          code
        }
      }
    }
  `,
  subjectAssessments: gql`
    query getSubjectAssessmentsQuery($subjectId: Int) {
      getSubjectAssessments(subjectId: $subjectId) {
        id
        teacher {
          id
          firstName
          lastName
        }
        topic
        deadline
        createdAt
        subjects {
          id
          name
          code
        }
        overallMark
        description
        fileUrl
        subjects {
          id
          code
        }
        percentage
      }
    }
  `,
  submitSubjectWorkPercentages: gql`
    mutation submitSubjectWorkPercentagesQueries($portions: [PortionInput]) {
      submitSubjectWorkPercentages(portions: $portions) {
        assessmentId
        type
        percentage
        subjectId
      }
    }
  `,
  subjectResult: gql`
    query getSubjectResultQuery($subjectId: Int) {
      getSubjectResult(subjectId: $subjectId) {
        name
        studentId
        regNumber
        email
        phoneNumber
        results {
          code
          subjectId
          averageScore
          highestScore
          creditUnit
          assessments {
            score
            topic
            overallMark
            percentage
            highestScore
            averageScore
          }
        }
      }
    }
  `,
  categories: gql`
    query {
      getCategories {
        id
        name
      }
    }
  `,
}
