export const indigoTheme = {
  bgPrimary50: 'bg-indigo-50',
  bgPrimary100: 'bg-indigo-100',
  bgPrimary200: 'bg-indigo-200',
  bgPrimary300: 'bg-indigo-300',
  bgPrimary400: 'bg-indigo-400',
  bgPrimary500: 'bg-indigo-500',
  bgPrimary600: 'bg-indigo-600',
  bgPrimary700: 'bg-indigo-700',
  bgPrimary800: 'bg-indigo-800',
  bgPrimary900: 'bg-indigo-900',

  textPrimary50: 'text-indigo-50',
  textPrimary100: 'text-indigo-100',
  textPrimary200: 'text-indigo-200',
  textPrimary300: 'text-indigo-300',
  textPrimary400: 'text-indigo-400',
  textPrimary600: 'text-indigo-600',
  textPrimary700: 'text-indigo-700',
  textPrimary800: 'text-indigo-800',
  textPrimary900: 'text-indigo-900',

  focusRingPrimary500: 'focus:ring-indigo-500',
  focusBorderPrimary500: 'focus:border-indigo-500',
  focusWithinRingPrimary500: 'focus-within:ring-indigo-500',

  hoverBgPrimary700: 'hover:bg-indigo-700',
  hoverTextPrimary500: 'hover:text-indigo-500',
  hoverBgPrimary600: 'hover:bg-indigo-600',
  hoverBgPrimary50: 'hover:bg-indigo-50 ',

  fromPrimary50: 'from-indigo-50',
  toPrimary100: 'to-indigo-100',
  toPrimary50: 'to-indigo-50',

  borderPrimary100: 'border-indigo-100',
  borderPrimary200: 'border-indigo-200',
  borderPrimary300: 'border-indigo-300',
  borderPrimary400: 'border-indigo-400',
  borderPrimary500: 'border-indigo-500',
  borderPrimary600: 'border-indigo-600',
  borderPrimary800: 'border-indigo-800',

  shadowPrimary300: 'shadow-indigo-300',
}

export const amberTheme = {
  bgPrimary50: 'bg-amber-50',
  bgPrimary100: 'bg-amber-100',
  bgPrimary200: 'bg-amber-200',
  bgPrimary300: 'bg-amber-300',
  bgPrimary400: 'bg-amber-400',
  bgPrimary500: 'bg-amber-500',
  bgPrimary600: 'bg-amber-600',
  bgPrimary700: 'bg-amber-700',
  bgPrimary800: 'bg-amber-800',
  bgPrimary900: 'bg-amber-900',

  textPrimary50: 'text-amber-50',
  textPrimary100: 'text-amber-100',
  textPrimary200: 'text-amber-200',
  textPrimary300: 'text-amber-300',
  textPrimary400: 'text-amber-400',
  textPrimary600: 'text-amber-600',
  textPrimary700: 'text-amber-700',
  textPrimary800: 'text-amber-800',
  textPrimary900: 'text-amber-900',

  focusRingPrimary500: 'focus:ring-amber-500',
  focusBorderPrimary500: 'focus:border-amber-500',
  focusWithinRingPrimary500: 'focus-within:ring-amber-500',

  hoverBgPrimary700: 'hover:bg-amber-700',
  hoverTextPrimary500: 'hover:text-amber-500',
  hoverBgPrimary600: 'hover:bg-amber-600',
  hoverBgPrimary50: 'hover:bg-amber-50 ',

  fromPrimary50: 'from-amber-50',
  toPrimary100: 'to-amber-100',
  toPrimary50: 'to-amber-50',

  borderPrimary100: 'border-amber-100',
  borderPrimary200: 'border-amber-200',
  borderPrimary300: 'border-amber-300',
  borderPrimary400: 'border-amber-400',
  borderPrimary500: 'border-amber-500',
  borderPrimary600: 'border-amber-600',
  borderPrimary800: 'border-amber-800',

  shadowPrimary300: 'shadow-amber-300',
}

export const greenTheme = {
  bgPrimary50: 'bg-green-50',
  bgPrimary100: 'bg-green-100',
  bgPrimary200: 'bg-green-200',
  bgPrimary300: 'bg-green-300',
  bgPrimary400: 'bg-green-400',
  bgPrimary500: 'bg-green-500',
  bgPrimary600: 'bg-green-600',
  bgPrimary700: 'bg-green-700',
  bgPrimary800: 'bg-green-800',
  bgPrimary900: 'bg-green-900',

  textPrimary50: 'text-green-50',
  textPrimary100: 'text-green-100',
  textPrimary200: 'text-green-200',
  textPrimary300: 'text-green-300',
  textPrimary400: 'text-green-400',
  textPrimary600: 'text-green-600',
  textPrimary700: 'text-green-700',
  textPrimary800: 'text-green-800',
  textPrimary900: 'text-green-900',

  focusRingPrimary500: 'focus:ring-green-500',
  focusBorderPrimary500: 'focus:border-green-500',
  focusWithinRingPrimary500: 'focus-within:ring-green-500',

  hoverBgPrimary700: 'hover:bg-green-700',
  hoverTextPrimary500: 'hover:text-green-500',
  hoverBgPrimary600: 'hover:bg-green-600',
  hoverBgPrimary50: 'hover:bg-green-50 ',

  fromPrimary50: 'from-green-50',
  toPrimary100: 'to-green-100',
  toPrimary50: 'to-green-50',

  borderPrimary100: 'border-green-100',
  borderPrimary200: 'border-green-200',
  borderPrimary300: 'border-green-300',
  borderPrimary400: 'border-green-400',
  borderPrimary500: 'border-green-500',
  borderPrimary600: 'border-green-600',
  borderPrimary800: 'border-green-800',

  shadowPrimary300: 'shadow-green-300',
}

export const purpleTheme = {
  bgPrimary50: 'bg-purple-50',
  bgPrimary100: 'bg-purple-100',
  bgPrimary200: 'bg-purple-200',
  bgPrimary300: 'bg-purple-300',
  bgPrimary400: 'bg-purple-400',
  bgPrimary500: 'bg-purple-500',
  bgPrimary600: 'bg-purple-600',
  bgPrimary700: 'bg-purple-700',
  bgPrimary800: 'bg-purple-800',
  bgPrimary900: 'bg-purple-900',

  textPrimary50: 'text-purple-50',
  textPrimary100: 'text-purple-100',
  textPrimary200: 'text-purple-200',
  textPrimary300: 'text-purple-300',
  textPrimary400: 'text-purple-400',
  textPrimary600: 'text-purple-600',
  textPrimary700: 'text-purple-700',
  textPrimary800: 'text-purple-800',
  textPrimary900: 'text-purple-900',

  focusRingPrimary500: 'focus:ring-purple-500',
  focusBorderPrimary500: 'focus:border-purple-500',
  focusWithinRingPrimary500: 'focus-within:ring-purple-500',

  hoverBgPrimary700: 'hover:bg-purple-700',
  hoverTextPrimary500: 'hover:text-purple-500',
  hoverBgPrimary600: 'hover:bg-purple-600',
  hoverBgPrimary50: 'hover:bg-purple-50 ',

  fromPrimary50: 'from-purple-50',
  toPrimary100: 'to-purple-100',
  toPrimary50: 'to-purple-50',

  borderPrimary100: 'border-purple-100',
  borderPrimary200: 'border-purple-200',
  borderPrimary300: 'border-purple-300',
  borderPrimary400: 'border-purple-400',
  borderPrimary500: 'border-purple-500',
  borderPrimary600: 'border-purple-600',
  borderPrimary800: 'border-purple-800',

  shadowPrimary300: 'shadow-purple-300',
}

export const blueTheme = {
  bgPrimary50: 'bg-blue-50',
  bgPrimary100: 'bg-blue-100',
  bgPrimary200: 'bg-blue-200',
  bgPrimary300: 'bg-blue-300',
  bgPrimary400: 'bg-blue-400',
  bgPrimary500: 'bg-blue-500',
  bgPrimary600: 'bg-blue-600',
  bgPrimary700: 'bg-blue-700',
  bgPrimary800: 'bg-blue-800',
  bgPrimary900: 'bg-blue-900',

  textPrimary50: 'text-blue-50',
  textPrimary100: 'text-blue-100',
  textPrimary200: 'text-blue-200',
  textPrimary300: 'text-blue-300',
  textPrimary400: 'text-blue-400',
  textPrimary600: 'text-blue-600',
  textPrimary700: 'text-blue-700',
  textPrimary800: 'text-blue-800',
  textPrimary900: 'text-blue-900',

  focusRingPrimary500: 'focus:ring-blue-500',
  focusBorderPrimary500: 'focus:border-blue-500',
  focusWithinRingPrimary500: 'focus-within:ring-blue-500',

  hoverBgPrimary700: 'hover:bg-blue-700',
  hoverTextPrimary500: 'hover:text-blue-500',
  hoverBgPrimary600: 'hover:bg-blue-600',
  hoverBgPrimary50: 'hover:bg-blue-50 ',

  fromPrimary50: 'from-blue-50',
  toPrimary100: 'to-blue-100',
  toPrimary50: 'to-blue-50',

  borderPrimary100: 'border-blue-100',
  borderPrimary200: 'border-blue-200',
  borderPrimary300: 'border-blue-300',
  borderPrimary400: 'border-blue-400',
  borderPrimary500: 'border-blue-500',
  borderPrimary600: 'border-blue-600',
  borderPrimary800: 'border-blue-800',

  shadowPrimary300: 'shadow-blue-300',
}

export const zincTheme = {
  bgPrimary50: 'bg-zinc-50',
  bgPrimary100: 'bg-zinc-100',
  bgPrimary200: 'bg-zinc-200',
  bgPrimary300: 'bg-zinc-300',
  bgPrimary400: 'bg-zinc-400',
  bgPrimary500: 'bg-zinc-500',
  bgPrimary600: 'bg-zinc-600',
  bgPrimary700: 'bg-zinc-700',
  bgPrimary800: 'bg-zinc-800',
  bgPrimary900: 'bg-zinc-900',

  textPrimary50: 'text-zinc-50',
  textPrimary100: 'text-zinc-100',
  textPrimary200: 'text-zinc-200',
  textPrimary300: 'text-zinc-300',
  textPrimary400: 'text-zinc-400',
  textPrimary600: 'text-zinc-600',
  textPrimary700: 'text-zinc-700',
  textPrimary800: 'text-zinc-800',
  textPrimary900: 'text-zinc-900',

  focusRingPrimary500: 'focus:ring-zinc-500',
  focusBorderPrimary500: 'focus:border-zinc-500',
  focusWithinRingPrimary500: 'focus-within:ring-zinc-500',

  hoverBgPrimary700: 'hover:bg-zinc-700',
  hoverTextPrimary500: 'hover:text-zinc-500',
  hoverBgPrimary600: 'hover:bg-zinc-600',
  hoverBgPrimary50: 'hover:bg-zinc-50 ',

  fromPrimary50: 'from-zinc-50',
  toPrimary100: 'to-zinc-100',
  toPrimary50: 'to-zinc-50',

  borderPrimary100: 'border-zinc-100',
  borderPrimary200: 'border-zinc-200',
  borderPrimary300: 'border-zinc-300',
  borderPrimary400: 'border-zinc-400',
  borderPrimary500: 'border-zinc-500',
  borderPrimary600: 'border-zinc-600',
  borderPrimary800: 'border-zinc-800',

  shadowPrimary300: 'shadow-zinc-300',
}

export const cyanTheme = {
  bgPrimary50: 'bg-cyan-50',
  bgPrimary100: 'bg-cyan-100',
  bgPrimary200: 'bg-cyan-200',
  bgPrimary300: 'bg-cyan-300',
  bgPrimary400: 'bg-cyan-400',
  bgPrimary500: 'bg-cyan-500',
  bgPrimary600: 'bg-cyan-600',
  bgPrimary700: 'bg-cyan-700',
  bgPrimary800: 'bg-cyan-800',
  bgPrimary900: 'bg-cyan-900',

  textPrimary50: 'text-cyan-50',
  textPrimary100: 'text-cyan-100',
  textPrimary200: 'text-cyan-200',
  textPrimary300: 'text-cyan-300',
  textPrimary400: 'text-cyan-400',
  textPrimary600: 'text-cyan-600',
  textPrimary700: 'text-cyan-700',
  textPrimary800: 'text-cyan-800',
  textPrimary900: 'text-cyan-900',

  focusRingPrimary500: 'focus:ring-cyan-500',
  focusBorderPrimary500: 'focus:border-cyan-500',
  focusWithinRingPrimary500: 'focus-within:ring-cyan-500',

  hoverBgPrimary700: 'hover:bg-cyan-700',
  hoverTextPrimary500: 'hover:text-cyan-500',
  hoverBgPrimary600: 'hover:bg-cyan-600',
  hoverBgPrimary50: 'hover:bg-cyan-50 ',

  fromPrimary50: 'from-cyan-50',
  toPrimary100: 'to-cyan-100',
  toPrimary50: 'to-cyan-50',

  borderPrimary100: 'border-cyan-100',
  borderPrimary200: 'border-cyan-200',
  borderPrimary300: 'border-cyan-300',
  borderPrimary400: 'border-cyan-400',
  borderPrimary500: 'border-cyan-500',
  borderPrimary600: 'border-cyan-600',
  borderPrimary800: 'border-cyan-800',

  shadowPrimary300: 'shadow-cyan-300',
}

export const emeraldTheme = {
  bgPrimary50: 'bg-emerald-50',
  bgPrimary100: 'bg-emerald-100',
  bgPrimary200: 'bg-emerald-200',
  bgPrimary300: 'bg-emerald-300',
  bgPrimary400: 'bg-emerald-400',
  bgPrimary500: 'bg-emerald-500',
  bgPrimary600: 'bg-emerald-600',
  bgPrimary700: 'bg-emerald-700',
  bgPrimary800: 'bg-emerald-800',
  bgPrimary900: 'bg-emerald-900',

  textPrimary50: 'text-emerald-50',
  textPrimary100: 'text-emerald-100',
  textPrimary200: 'text-emerald-200',
  textPrimary300: 'text-emerald-300',
  textPrimary400: 'text-emerald-400',
  textPrimary600: 'text-emerald-600',
  textPrimary700: 'text-emerald-700',
  textPrimary800: 'text-emerald-800',
  textPrimary900: 'text-emerald-900',

  focusRingPrimary500: 'focus:ring-emerald-500',
  focusBorderPrimary500: 'focus:border-emerald-500',
  focusWithinRingPrimary500: 'focus-within:ring-emerald-500',

  hoverBgPrimary700: 'hover:bg-emerald-700',
  hoverTextPrimary500: 'hover:text-emerald-500',
  hoverBgPrimary600: 'hover:bg-emerald-600',
  hoverBgPrimary50: 'hover:bg-emerald-50 ',

  fromPrimary50: 'from-emerald-50',
  toPrimary100: 'to-emerald-100',
  toPrimary50: 'to-emerald-50',

  borderPrimary100: 'border-emerald-100',
  borderPrimary200: 'border-emerald-200',
  borderPrimary300: 'border-emerald-300',
  borderPrimary400: 'border-emerald-400',
  borderPrimary500: 'border-emerald-500',
  borderPrimary600: 'border-emerald-600',
  borderPrimary800: 'border-emerald-800',

  shadowPrimary300: 'shadow-emerald-300',
}

export const baseTheme = {
  ...blueTheme,
  bgPrimary: 'bg-gray-50',
  textPrimary: 'text-gray-600',
  textSecondary: 'text-gray-400',
  textInactive: 'text-gray-100',
  hoverBgPrimary: 'hover:bg-gray-100',
  textDanger: 'text-red-400',
  textWarning: 'text-yellow-600',
  borderHighlight: 'border-amber-500',
  bgHighlight: 'bg-pink-500',
  pageBgColor: 'bg-blue-100/30',
}

export const themes = {
  blueTheme,
  cyanTheme,
  zincTheme,
  indigoTheme,
  purpleTheme,
  greenTheme,
  amberTheme,
  emeraldTheme,
  baseTheme,
}
