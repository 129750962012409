export const PAYMENT_METHODS = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  CASH: 'CASH',
  CARD: 'FLUTTER_WAVE',
  DEPOSIT: 'DEPOSIT',
}

export const PAYMENT_METHODS_DISPLAY_TEXT = {
  [PAYMENT_METHODS.BANK_TRANSFER]: 'Bank Transfer',
  [PAYMENT_METHODS.CASH]: 'Cash',
  [PAYMENT_METHODS.CARD]: 'Card',
  [PAYMENT_METHODS.DEPOSIT]: 'Deposit',
}

export const PAYMENT_METHOD_OPTIONS = [
  {
    text: PAYMENT_METHODS_DISPLAY_TEXT[PAYMENT_METHODS.BANK_TRANSFER],
    value: PAYMENT_METHODS.BANK_TRANSFER,
  },
  {
    text: PAYMENT_METHODS_DISPLAY_TEXT[PAYMENT_METHODS.CASH],
    value: PAYMENT_METHODS.CASH,
  },
  {
    text: PAYMENT_METHODS_DISPLAY_TEXT[PAYMENT_METHODS.CARD],
    value: PAYMENT_METHODS.CARD,
  },
  {
    text: PAYMENT_METHODS_DISPLAY_TEXT[PAYMENT_METHODS.DEPOSIT],
    value: PAYMENT_METHODS.DEPOSIT,
  },
]
