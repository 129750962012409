import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';
import { classNames } from '../../functions';
import useAppTheme from '../../hooks/useAppTheme';
import { COLOR_TYPES } from '../../constants/colorTypes';

const colors = {
  [COLOR_TYPES.danger]: {
    bgColor: 'bg-red-600',
    focusColor: 'focus:ring-red-500'
  },
  [COLOR_TYPES.success]: {
    bgColor: 'bg-green-600',
    focusColor: 'focus:ring-green-500'
  },
}

function SwitchToggle({
  value,
  onChange,
  disabled,
  label,
  name,
  type
}) {

  const enabled = !!value;
  const { bgPrimary600, focusRingPrimary500 } = useAppTheme();
  const bgColor = colors[type]?.bgColor || bgPrimary600;
  const focusColor = colors[type]?.focusColor || focusRingPrimary500;
  return (<Switch.Group as="div" className="flex items-center">
    <Switch
      checked={enabled}
      onChange={(val) => onChange(val, name)}
      disabled={disabled}
      className={classNames(
        enabled ? bgColor : 'bg-gray-200',
        `relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 
        border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 
        ${focusColor} focus:ring-offset-1`
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-3' : 'translate-x-0',
          'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
    <Switch.Label as="span" className="ml-3">
      <span className="text-xs font-medium text-gray-700">{label}</span>
    </Switch.Label>
  </Switch.Group>);
}

SwitchToggle.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string
};

export default SwitchToggle;