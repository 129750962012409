import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseComponent from '../components/SuspenseComponent';

const ViewQuestion = lazy(() => import('../views/QuestionPage/ViewQuestion'));
const CreateQuestion = lazy(() => import('../views/QuestionPage/CreateQuestion'));
const EditQuestion = lazy(() => import('../views/QuestionPage/EditQuestion'));
const DeleteQuestion = lazy(() => import('../views/QuestionPage/DeleteQuestion'));
const QuestionPage = lazy(() => import('../views/QuestionPage/Questions'));

function QuestionRoutes() {
  return <Routes>
    <Route path="create" element={ <SuspenseComponent><CreateQuestion/></SuspenseComponent> } />
    <Route path="/" element={ <SuspenseComponent><QuestionPage/></SuspenseComponent> } >
      <Route path=":id" element={ <SuspenseComponent><ViewQuestion/></SuspenseComponent> } >
        <Route path="delete" element={ <SuspenseComponent><DeleteQuestion/></SuspenseComponent> } />
      </Route>
      <Route path=":id/update" element={ <SuspenseComponent><EditQuestion/></SuspenseComponent> } />
    </Route>
  </Routes>;
}

export default QuestionRoutes;
