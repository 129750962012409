export const PASSWORD = 'password'
export const TEXT_INPUT = 'text'
export const TAG_INPUT = 'tagInput'
export const TEXT_EDITOR = 'text_editor'
export const SELECT_INPUT = 'select'
export const MULTISELECT = 'multiselect'
export const SEARCH_MULTISELECT = 'search_multiselect'
export const SEARCH_SINGLESELECT = 'searchSingleselect'
export const DATE_INPUT = 'date'
export const UPLOAD_INPUT = 'upload'
export const ANSWERS = 'answers'
export const MULTIBUTTONS = 'multibuttons'
export const ZOOM_CREATE = 'zoomcreate'
export const SINGLE_BUTTON_SELECT = 'singleButtonSelect'
export const SWITCH = 'switch'
