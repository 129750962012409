import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../Spinner';

function ErrorView({ loading }) {
  return loading ?
  <Spinner />
  : <div
    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
    role="alert"
  >
    <strong className="font-bold">Holy smokes! </strong>
    <span className="block sm:inline">Something seriously bad happened.</span>
  </div>
}

ErrorView.propTypes = {
  loading: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  error: PropTypes.any,
};

export default ErrorView;
